import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import Select from 'react-select';
import { fetchTeacherDetails, getVideo } from '../store/actions/teachersActions';
import SmallCalendar from './SmallCalendar';
import './TeacherDetails.css';

const TeacherDetails = () => {
  const { TeacherID } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation("global");
  
  const [videoUrl, setVideoUrl] = useState(null);
  const [localError, setLocalError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState(moment.tz.guess());
  const [hourFormat, setHourFormat] = useState('24');

  const { TeacherDetails: teacher, loading, error } = useSelector((state) => state.teachers);

  useEffect(() => {
    if (TeacherID) {
      dispatch(fetchTeacherDetails(TeacherID));
    } else {
      setLocalError(t('TeacherDetails.noTeacherID'));
    }
  }, [TeacherID, dispatch, t]);

  useEffect(() => {
    console.log('Teacher Data:', teacher);

    if (teacher && teacher.Video) {
      dispatch(getVideo(teacher.Video))
        .unwrap()
        .then(url => {
          setVideoUrl(url);
        })
        .catch(err => {
          setLocalError(t('TeacherDetails.videoLoadError', { error: err.message }));
        });
    }

    // Load timezone and hour format from localStorage
    const storedTimezone = localStorage.getItem('selectedTimezone');
    if (storedTimezone) {
      setSelectedTimezone(storedTimezone);
    }

    const storedHourFormat = localStorage.getItem('hourFormat');
    if (storedHourFormat) {
      setHourFormat(storedHourFormat);
    }
  }, [teacher, dispatch, t]);

  useEffect(() => {
    if (teacher && teacher.Availability) {
      updateTimeSlots(selectedDate);
    }
  }, [teacher, selectedDate, selectedTimezone, hourFormat]);

  const handleDateChange = (date) => {
    const formattedDate = date.toISOString().split('T')[0];
    setSelectedDate(formattedDate);
  };

  const updateTimeSlots = (date) => {
    const slots = teacher.Availability[date]?.slots || [];
    const convertedSlots = slots.map(slot => ({
      start: convertTime(date, slot.start, teacher.userTimezone, selectedTimezone),
      end: convertTime(date, slot.end, teacher.userTimezone, selectedTimezone)
    }));
    setSelectedTimeSlots(convertedSlots);
  };

  const convertTime = (date, time, fromTimezone, toTimezone) => {
    const datetime = moment.tz(`${date} ${time}`, fromTimezone);
    return datetime.tz(toTimezone).format(hourFormat === '12' ? 'hh:mm A' : 'HH:mm');
  };

  const handleTimezoneChange = (selectedOption) => {
    const timezone = selectedOption.value;
    setSelectedTimezone(timezone);
    localStorage.setItem('selectedTimezone', timezone);
  };

  const handleHourFormatChange = (event) => {
    const format = event.target.value;
    setHourFormat(format);
    localStorage.setItem('hourFormat', format);
  };

  const timezoneOptions = moment.tz.names().map((tz) => ({
    value: tz,
    label: tz,
  }));

  const truncateDescription = (description, wordLimit = 6) => {
    const words = description.split(' ');
    if (words.length <= wordLimit) return description;
    return words.slice(0, wordLimit).join(' ') + '...';
  };

  const CourseItem = ({ course }) => {
    const [showFullDescription, setShowFullDescription] = useState(false);

    return (
      <li>
        <h3>{course.Course_Name || t('TeacherDetails.unnamedCourse')}</h3>
        {course.Description && (
          <p>
            {showFullDescription ? course.Description : truncateDescription(course.Description)}
            {course.Description.split(' ').length > 6 && (
              <button 
                className="td-link"
                onClick={() => setShowFullDescription(!showFullDescription)}
              >
                {showFullDescription ? t('TeacherDetails.readLess') : t('TeacherDetails.readMore')}
              </button>
            )}
          </p>
        )}
        {course.Purchase_Price && (
          <p>{t('TeacherDetails.price', { price: course.Purchase_Price })}</p>
        )}
      </li>
    );
  };

  if (loading) {
    return <div className="td-loading">{t('common.loading')}</div>;
  }

  if (error || localError) {
    return <div className="td-error">{error || localError}</div>;
  }

  if (!teacher) {
    return <div className="td-error">{t('TeacherDetails.noTeacherData')}</div>;
  }

  return (
    <div className="td-container">
      <div className="td-teacher-profile">
        <div className="td-profile-header">
          <div className="td-profile-image-container">
            <img
              src={teacher.Profile_Image && teacher.Profile_Image[0] 
                ? `https://ik.imagekit.io/8s3jwexmv/${encodeURIComponent(teacher.Profile_Image[0])}` 
                : "https://ik.imagekit.io/8s3jwexmv/default-profile-image.png"}
              alt={teacher.Username || t('TeacherDetails.teacher')}
              className="td-profile-image"
            />
          </div>
          <div className="td-profile-info">
            <h1 className="td-teacher-name">{teacher.Username || t('TeacherDetails.unnamedTeacher')}</h1>
            <p className="td-teacher-title">{teacher.Short_Title || t('TeacherDetails.teacher')}</p>
          </div>
        </div>

        <div className="td-content-section">
          <div className="td-main-content">
            <div className="td-about-section">
              <h2>{t('TeacherDetails.about')}</h2>
              <p>{teacher.Description || t('TeacherDetails.noDescription')}</p>
            </div>

            <div className="td-video-container">
              {videoUrl ? (
                <iframe
                  src={videoUrl}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  title={t('TeacherDetails.introVideo')}
                ></iframe>
              ) : (
                <div className="td-no-video">
                  {t('TeacherDetails.noVideo')}
                </div>
              )}
            </div>
          </div>

          <div className="td-side-content">
            <div className="td-info-card td-calendar-section">
              <h2>{t('TeacherDetails.calendar')}</h2>
              <div className="td-timezone-selector">
                <label htmlFor="timezone-select">{t('TeacherDetails.selectTimezone')}:</label>
                <Select
                  id="timezone-select"
                  value={timezoneOptions.find(option => option.value === selectedTimezone)}
                  onChange={handleTimezoneChange}
                  options={timezoneOptions}
                  isSearchable
                  className="td-select"
                />
              </div>
              <div className="td-hour-format-selector">
                <label htmlFor="hour-format-select">{t('TeacherDetails.hourFormat')}:</label>
                <select
                  id="hour-format-select"
                  value={hourFormat}
                  onChange={handleHourFormatChange}
                  className="form-control"
                >
                  <option value="12">12-hour</option>
                  <option value="24">24-hour</option>
                </select>
              </div>
              <SmallCalendar 
                onChange={handleDateChange} 
                availableDates={teacher.Availability || {}}
              />
              {selectedTimeSlots.length > 0 && (
                <div className="td-time-slots">
                  <h3>{t('TeacherDetails.availableSlots')}</h3>
                  <ul>
                    {selectedTimeSlots.map((slot, index) => (
                      <li key={index}>{slot.start} - {slot.end}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            {Array.isArray(teacher.Courses_assign) && teacher.Courses_assign.length > 0 ? (
              <div className="td-info-card td-courses-section">
                <h2>{t('TeacherDetails.assignedCourses')}</h2>
                <ul>
                  {teacher.Courses_assign.map((course) => (
                    <CourseItem key={course._id || `course-${Math.random()}`} course={course} />
                  ))}
                </ul>
              </div>
            ) : (
              <div className="td-info-card td-courses-section">
                <h2>{t('TeacherDetails.assignedCourses')}</h2>
                <p>{t('TeacherDetails.noCourses')}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherDetails;
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axiosconfig";

// ACTIONS : api calls
export const fetchAllbookings = createAsyncThunk(
  "bookings/getbookings",
  async () => {
    const response = await axios.get(`getbookings`);
    // console.log(response);
    return response.data;
  }
);

export const Add_booking = createAsyncThunk(
  "bookings/Add_booking",
  async (formData) => {
    const response = await axios.post(`/Create_Booking`, formData);
    return response.data;
  }
);

export const fetchExistingBookings = createAsyncThunk(
  'bookings/fetchExisting',
  async ({ studentId, teacherId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/student-bookings/${studentId}/${teacherId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching bookings:', error.response?.data || error.message);
      return rejectWithValue(error.response?.data || 'An error occurred while fetching bookings');
    }
  }
);

export const Deletebooking = createAsyncThunk(
  "bookings/Deletebooking",
  async (BookingID) => {
    try {
      const response = await axios.get(`Delete_Booking/${BookingID}`);
      return BookingID; 
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const Updatebooking = createAsyncThunk(
  "bookings/Updatebooking",
  async ({ BookingID, updatedData }) => {
    console.log("Updating booking:", BookingID, updatedData);
    try {
      const response = await axios.post(
        `Update_Booking/${BookingID}`,
        updatedData
      );
      console.log("Update response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error updating booking:", error.response?.data || error.message);
      throw error;
    }
  }
);

export const GetBookingsByStudentID = createAsyncThunk(
  "bookings/GetBookingsByStudentID",
  async (id) => {
    // console.log(id)
    try {
      const response = await axios.get(`GetBookingsByStudentID/${id}`);
      // console.log(response);
      return response.data.Bookings;
    } catch (error) {
      console.error("Error fetching meetings:", error);
      throw error; // Propagate the error to be caught by Redux Toolkit
    }
  }
);

export const GetBookingsByTeacherID = createAsyncThunk(
  "bookings/GetBookingsByTeacherID",
  async (TeacherID) => {
    // console.log(TeacherID);
    try {
      const response = await axios.get(`GetBookingsByTeacherID/${TeacherID}`);
      // console.log(response);
      return response.data.Bookings;
    } catch (error) {
      console.log(error);
    }
  }
);

export const GetExistingTeacherAvailability = createAsyncThunk(
  "bookings/GetExistingTeacherAvailability",
  async (BookingID, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/GetExistingTeacher_Availability/${BookingID}`);
      // Only return the data, not the entire response
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const GetExistingTeacherAvailability__oF_Package = createAsyncThunk(
  "bookings/GetExistingTeacherAvailability__oF_Package",
  async (Package_ID) => {
    // console.log(Package_ID)
    try {
      const response = await axios.get(
        `GetExistingTeacherAvailability__oF_Package/${Package_ID}`
      );
      // console.log(response)
      return response;
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);

export const fetchBookingsExcludingStudent = createAsyncThunk(
  'bookings/fetchExcludingStudent',
  async ({ studentId, teacherId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`bookings/exclude/${studentId}/${teacherId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching bookings:', error.response?.data || error.message);
      return rejectWithValue(error.response?.data || 'An error occurred while fetching bookings');
    }
  }
);

// SearchBookingbyStudentUsername
export const SearchBookingbyStudentUsername = createAsyncThunk(
  "bookings/SearchBookingbyStudentUsername",
  async (input) => {
    // console.log(input)
    try {
      const response = await axios.post(
        `SearchBookingbyStudentUsername/${input}`
      );
      // console.log(response)
      return response;
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);

// UpdatetheBookingStatus;
export const UpdatetheBookingStatus = createAsyncThunk(
  "bookings/UpdatetheBookingStatus",
  async (bookingId) => {
    // console.log(input)
    try {
      const response = await axios.get(`UpdatetheBookingStatus/${bookingId}`);
      // console.log(response)
      return response;
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);

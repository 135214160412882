import React, { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AdminNav from "../AdminNav";
import { notification, Select, Button, Checkbox, TimePicker, Modal, Row, Col, Typography, Divider, Switch } from "antd";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import "../Calendar.css";
import { updateTeacher, fetchTeacherDetails } from "../../../store/actions/teachersActions";
import moment from 'moment-timezone';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { v4 as uuidv4 } from 'uuid';
import { EditOutlined, DeleteOutlined, PlusOutlined, CalendarOutlined } from '@ant-design/icons';
import { 
  addDays, 
  format, 
  startOfMonth, 
  endOfMonth, 
  startOfWeek,
  endOfWeek,
  addMonths,
  isSameMonth,
  isSameDay,
  parseISO
} from 'date-fns';
import "./AdminEditTeacherEditAvailability.css";

dayjs.extend(utc);
dayjs.extend(timezone);

const { Title, Text } = Typography;
const { Option } = Select;

const timeZones = moment.tz.names();
const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const CustomDay = ({ day, selectedDates }) => {
  const dateStr = format(day, 'yyyy-MM-dd');
  const isSelected = selectedDates[dateStr];

  return (
    <div className={`custom-day ${isSelected ? 'selected' : ''}`}>
      {format(day, 'd')}
    </div>
  );
};

const MiniCalendar = ({ selectedDates, onDateToggle, currentMonth, setCurrentMonth }) => {
  const firstDayOfMonth = startOfMonth(currentMonth);
  const lastDayOfMonth = endOfMonth(currentMonth);
  const startDate = startOfWeek(firstDayOfMonth);
  const endDate = endOfWeek(lastDayOfMonth);

  const dateFormat = "d";
  const rows = [];
  let days = [];
  let day = startDate;

  const changeMonth = (amount) => {
    setCurrentMonth(addMonths(currentMonth, amount));
  };

  while (day <= endDate) {
    for (let i = 0; i < 7; i++) {
      const cloneDay = day;
      const dateStr = format(cloneDay, 'yyyy-MM-dd');
      days.push(
        <div
          className={`calendar-day ${
            !isSameMonth(day, firstDayOfMonth) ? "disabled" :
            isSameDay(day, new Date()) ? "today" : ""
          } ${selectedDates[dateStr] ? "selected" : ""}`}
          key={day}
          onClick={() => onDateToggle(dateStr)}
        >
          <span>{format(day, dateFormat)}</span>
        </div>
      );
      day = addDays(day, 1);
    }
    rows.push(
      <div className="calendar-row" key={day}>
        {days}
      </div>
    );
    days = [];
  }

  return (
    <div className="mini-calendar-container">
      <div className="calendar-header">
        <button onClick={() => changeMonth(-1)}>&lt;</button>
        <h3>{format(currentMonth, 'MMMM yyyy')}</h3>
        <button onClick={() => changeMonth(1)}>&gt;</button>
      </div>
      <div className="calendar-days">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <div className="calendar-day-header" key={day}>{day}</div>
        ))}
      </div>
      <div className="calendar-body">
        {rows}
      </div>
    </div>
  );
};

const AdminEditTeacherEditAvailability = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const teacherData = useSelector(state => state.teachers.currentTeacher);

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [userTimezone, setUserTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [hourFormat, setHourFormat] = useState('24');
  const [showMiniCalendar, setShowMiniCalendar] = useState(false);
  const [minDate, setMinDate] = useState(new Date());
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDates, setSelectedDates] = useState({});
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
  const [isLongRangeModalVisible, setIsLongRangeModalVisible] = useState(false);
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState({});
  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  });

  const [selectedDays, setSelectedDays] = useState({
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false
  });

  const [availability, setAvailability] = useState(() => {
    return daysOfWeek.reduce((acc, day) => {
      acc[day] = { 
        enabled: false, 
        slots: [], 
        isEditing: false 
      };
      return acc;
    }, {});
  });

  useEffect(() => {
    dispatch(fetchTeacherDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (teacherData) {
      if (teacherData.selectedDates) {
        setSelectedDates(teacherData.selectedDates);
        
        const selectedDatesList = Object.keys(teacherData.selectedDates).filter(date => teacherData.selectedDates[date]);
        if (selectedDatesList.length > 0) {
          setState({
            selection: {
              startDate: new Date(selectedDatesList[0]),
              endDate: new Date(selectedDatesList[selectedDatesList.length - 1]),
              key: 'selection'
            }
          });
        }
      }
  
      if (teacherData.selectedDays) {
        setSelectedDays(teacherData.selectedDays);
      }
  
      if (teacherData.Availability) {
        const availabilityData = teacherData.Availability;
        
        setAvailability(prevAvailability => {
          const newAvailability = { ...prevAvailability };

          Object.entries(availabilityData).forEach(([date, data]) => {
            if (data && Array.isArray(data.slots)) {
              const dayOfWeek = data.dayOfWeek;
              newAvailability[dayOfWeek] = {
                ...newAvailability[dayOfWeek],
                enabled: true,
                slots: data.slots.map(slot => ({
                  id: uuidv4(),
                  fromTime: dayjs.utc(slot.start, 'HH:mm'),
                  toTime: dayjs.utc(slot.end, 'HH:mm')
                })),
                isEditing: false
              };
            }
          });

          return newAvailability;
        });
      }
  
      if (teacherData.userTimezone) {
        setUserTimezone(teacherData.userTimezone);
      }
    }
  
    const storedHourFormat = localStorage.getItem('AdminHourFormat');
    if (storedHourFormat) {
      setHourFormat(storedHourFormat);
    }
  
    setMinDate(new Date());
  
  }, [teacherData]);

  const handleCheckboxChange = (day) => {
    setAvailability({
      ...availability,
      [day]: { ...availability[day], enabled: !availability[day].enabled },
    });
  };

  const handleTimeChange = (day, index, field, newValue) => {
    const newSlots = availability[day].slots.map((slot, i) => {
      if (i === index) {
        if (field === 'fromTime') {
          const toTime = newValue.add(25, 'minute');
          return { ...slot, fromTime: newValue.utc(), toTime: toTime.utc() };
        }
        return { ...slot, [field]: newValue.utc() };
      }
      return slot;
    });
    setAvailability({
      ...availability,
      [day]: { ...availability[day], slots: newSlots },
    });
  };

  const handleAddSlot = (day) => {
    setAvailability(prevAvailability => {
      const fromTime = dayjs().utc().hour(12).minute(0);
      const toTime = fromTime.add(25, 'minute');
      const updatedSlots = [...(prevAvailability[day]?.slots || [])];
      updatedSlots.push({ id: uuidv4(), fromTime, toTime });
      return {
        ...prevAvailability,
        [day]: {
          ...prevAvailability[day],
          slots: updatedSlots
        }
      };
    });
  };

  const handleAddConsecutiveSlot = (day, index) => {
    setAvailability(prevAvailability => {
      const updatedSlots = [...prevAvailability[day].slots];
      const currentSlot = updatedSlots[index];
      const newFromTime = currentSlot.toTime;
      const newToTime = newFromTime.add(25, 'minute');
      updatedSlots.splice(index + 1, 0, { id: uuidv4(), fromTime: newFromTime, toTime: newToTime });
      return {
        ...prevAvailability,
        [day]: {
          ...prevAvailability[day],
          slots: updatedSlots
        }
      };
    });
  };

  const handleRemoveSlot = (day, index) => {
    const newSlots = availability[day].slots.filter((_, i) => i !== index);
    setAvailability({
      ...availability,
      [day]: { ...availability[day], slots: newSlots },
    });
  };

  const handleEditClick = (day) => {
    setAvailability({
      ...availability,
      [day]: { ...availability[day], isEditing: !availability[day].isEditing },
    });
  };

  const handleRangeChange = (item) => {
    const newSelectedDates = { ...selectedDates };
    let currentDate = new Date(item.selection.startDate);
    const endDate = new Date(item.selection.endDate);
  
    while (currentDate <= endDate) {
      const dateStr = format(currentDate, 'yyyy-MM-dd');
      const dayName = format(currentDate, 'EEEE');
      newSelectedDates[dateStr] = selectedDays[dayName];
      currentDate = addDays(currentDate, 1);
    }
  
    setSelectedDates(newSelectedDates);
    setState({ ...state, ...item });
  };

  const handleDateToggle = (dateStr) => {
    const newSelectedDates = {
      ...selectedDates,
      [dateStr]: !selectedDates[dateStr]
    };
    setSelectedDates(newSelectedDates);

    const selectedDatesList = Object.keys(newSelectedDates).filter(date => newSelectedDates[date]);
    if (selectedDatesList.length > 0) {
      const startDate = new Date(selectedDatesList[0]);
      const endDate = new Date(selectedDatesList[selectedDatesList.length - 1]);
      setState({
        ...state,
        selection: {
          startDate,
          endDate,
          key: 'selection'
        }
      });
    } else {
      setState({
        ...state,
        selection: {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
      });
    }
  };

  const handleDayCheckboxChange = (day) => {
    setSelectedDays(prevDays => {
      const newDays = { ...prevDays, [day]: !prevDays[day] };
      
      setAvailability(prevAvailability => ({
        ...prevAvailability,
        [day]: { 
          ...prevAvailability[day], 
          enabled: newDays[day]
        }
      }));
  
      setSelectedDates(prevDates => {
        const newDates = { ...prevDates };
        const startDate = state.selection.startDate;
        const endDate = state.selection.endDate;
        let currentDate = new Date(startDate);
  
        while (currentDate <= endDate) {
          const dateStr = format(currentDate, 'yyyy-MM-dd');
          const dayOfWeek = format(currentDate, 'EEEE');
          
          if (dayOfWeek === day) {
            newDates[dateStr] = newDays[day];
          }
  
          currentDate = addDays(currentDate, 1);
        }
  
        return newDates;
      });
  
      return newDays;
    });
  };

  const handleLongRangeSelection = () => {
    const newSelectedDates = {};
    selectedYears.forEach(year => {
      const startOfYear = new Date(year, 0, 1);
      const endOfYear = new Date(year, 11, 31);
      let currentDate = startOfYear;
  
      while (currentDate <= endOfYear) {
        const dateStr = format(currentDate, 'yyyy-MM-dd');
        const monthStr = format(currentDate, 'yyyy-MM');
        const dayName = format(currentDate, 'EEEE');
  
        if (selectedMonths[monthStr] !== false) {
          newSelectedDates[dateStr] = selectedDays[dayName];
        }
  
        currentDate = addDays(currentDate, 1);
      }
    });
  
    setSelectedDates(newSelectedDates);
    setState({
      ...state,
      selection: {
        startDate: new Date(Math.min(...Object.keys(newSelectedDates).map(date => new Date(date)))),
        endDate: new Date(Math.max(...Object.keys(newSelectedDates).map(date => new Date(date)))),
        key: 'selection'
      }
    });
    setIsLongRangeModalVisible(false);
  };

  const handleDeleteEntireAvailability = () => {
    setSelectedDates({});
    setAvailability(
      daysOfWeek.reduce((acc, day) => {
        acc[day] = { enabled: false, slots: [], isEditing: false };
        return acc;
      }, {})
    );
    setSelectedDays(
      daysOfWeek.reduce((acc, day) => {
        acc[day] = false;
        return acc;
      }, {})
    );
    setIsDeleteConfirmVisible(false);
  };

  const handleSubmit = async () => {
    try {
      const availabilityData = Object.entries(selectedDates).reduce((acc, [date, isSelected]) => {
        if (isSelected) {
          const dayOfWeek = format(new Date(date), 'EEEE');
          if (selectedDays[dayOfWeek] && availability[dayOfWeek]?.slots.length > 0) {
            acc[date] = {
              dayOfWeek,
              slots: availability[dayOfWeek].slots.map(slot => ({
                start: slot.fromTime.format('HH:mm'),
                end: slot.toTime.format('HH:mm')
              }))
            };
          }
        }
        return acc;
      }, {});

      await dispatch(
        updateTeacher({
          teacherId: id,
          updatedData: {
            ...teacherData,
            Availability: availabilityData,
            selectedDays,
            userTimezone,
          },
        })
      );
      setIsModalVisible(false);
      notification.success({
        message: 'Success',
        description: 'Teacher availability updated successfully.',
      });
      navigate("/Admin-Dashboard/Teachers");
    } catch (error) {
      console.error("Error editing teacher:", error);
      notification.error({
        message: 'Submission Error',
        description: 'There was an error editing the teacher. Please try again.',
      });
    }
  };

  return (
    <>
      <AdminNav />
      <div className="styled-container">
        <div className="calendar-container">
          <h2>Edit Teacher Availability</h2>
          
          <div className="day-selection">
            {Object.keys(selectedDays).map(day => (
              <div key={day} className="day-switch">
                <Switch
                  checked={selectedDays[day]}
                  onChange={() => handleDayCheckboxChange(day)}
                  className="day-switch-toggle"
                />
                <span className={selectedDays[day] ? 'day-label' : 'day-label-disabled'}>
                  {day}
                </span>
              </div>
            ))}
          </div>
          
          <DateRangePicker
            onChange={item => handleRangeChange(item)}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[state.selection]}
            direction="horizontal"
            minDate={minDate}
            dayContentRenderer={day => <CustomDay day={day} selectedDates={selectedDates} />}
          />
          
          <div className="mini-calendar-switch">
            <Switch
              checked={showMiniCalendar}
              onChange={setShowMiniCalendar}
              checkedChildren="Edit Specific Dates"
              unCheckedChildren="Edit Specific Dates"
            />
          </div>
          
          {showMiniCalendar && (
            <MiniCalendar 
              selectedDates={selectedDates}
              onDateToggle={handleDateToggle}
              currentMonth={currentMonth}
              setCurrentMonth={setCurrentMonth}
            />
          )}
          
          <div className="action-buttons">
            <Button 
              icon={<CalendarOutlined />}
              onClick={() => setIsLongRangeModalVisible(true)}
              className="select-long-range-button"
            >
              Select Long Range
            </Button>
            <Button 
              onClick={() => setIsModalVisible(true)}
              type="primary"
              className="set-availability-button"
            >
              Set Availability for Selected Dates
            </Button>
            <Button 
              onClick={() => setIsDeleteConfirmVisible(true)}
              type="danger"
              className="delete-availability-button"
            >
              Delete Entire Availability
            </Button>
          </div>
        </div>
      </div>
  
      <Modal
        title="Weekly Availability"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Submit
          </Button>,
        ]}
        width={800}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="modal-content">
            <div className="timezone-selector">
              <label>Timezone availability:</label>
              <Select
                style={{ width: 200 }}
                value={userTimezone}
                onChange={setUserTimezone}
                showSearch
                optionFilterProp="children" 
                placeholder="Select a timezone" 
                filterOption={(input, option) =>
                  (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                } 
              >
                {timeZones.map(tz => (
                  <Option key={tz} value={tz}>
                    {tz}
                  </Option>
                ))}
              </Select>
            </div>
            {daysOfWeek.map((day) => (
              <div key={day} className="day-availability">
                <div className="day-switch">
                  <Switch
                    checked={selectedDays[day]}
                    onChange={() => handleDayCheckboxChange(day)}
                    className="day-switch-toggle"
                  />
                  <span className={selectedDays[day] ? 'day-label' : 'day-label-disabled'}>
                    {day}
                  </span>
                </div>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => handleEditClick(day)}
                  type={availability[day]?.isEditing ? "primary" : "default"}
                  className="edit-button"
                  disabled={!selectedDays[day]}
                  style={{ 
                    opacity: selectedDays[day] ? 1 : 0.5,
                    cursor: selectedDays[day] ? 'pointer' : 'not-allowed'
                  }}
                >
                  {availability[day]?.isEditing ? 'Save' : 'Edit'}
                </Button>
                {selectedDays[day] && availability[day] && (
                  <div className="time-slots">
                    {(availability[day].slots || []).map((slot, index) => (
                      <div key={slot.id} className="time-slot">
                        {availability[day]?.isEditing ? (
                          <>
                            <TimePicker
                              value={slot.fromTime}
                              onChange={(time) => handleTimeChange(day, index, 'fromTime', time)}
                              format={hourFormat === '24' ? 'HH:mm' : 'h:mm a'}
                              minuteStep={5}
                            />
                            <TimePicker
                              value={slot.toTime}
                              onChange={(time) => handleTimeChange(day, index, 'toTime', time)}
                              format={hourFormat === '24' ? 'HH:mm' : 'h:mm a'}
                              minuteStep={5}
                              disabled={true}
                            />
                            <Button
                              icon={<PlusOutlined />}
                              onClick={() => handleAddConsecutiveSlot(day, index)}
                              className="add-consecutive-slot-button"
                              disabled={index !== availability[day].slots.length - 1}
                            >
                              Add Consecutive
                            </Button>
                            <Button
                              icon={<DeleteOutlined />}
                              onClick={() => handleRemoveSlot(day, index)}
                              danger
                              className="delete-button"
                            />
                          </>
                        ) : (
                          <span>
                            {slot.fromTime.format(hourFormat === '24' ? 'HH:mm' : 'h:mm a')} - {slot.toTime.format(hourFormat === '24' ? 'HH:mm' : 'h:mm a')}
                          </span>
                        )}
                      </div>
                    ))}
                    {availability[day]?.isEditing && (
                      <Button icon={<PlusOutlined />} onClick={() => handleAddSlot(day)} className="add-slot-button">
                        Add Slot
                      </Button>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </LocalizationProvider>
      </Modal>

      <Modal
        title="Confirm Deletion"
        visible={isDeleteConfirmVisible}
        onCancel={() => setIsDeleteConfirmVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsDeleteConfirmVisible(false)}>
            Cancel
          </Button>,
          <Button key="delete" type="danger" onClick={handleDeleteEntireAvailability}>
            Delete
          </Button>,
        ]}
      >
        <p>Are you sure you want to delete the entire availability? This action cannot be undone.</p>
      </Modal>

      <Modal
        title={<Title level={3}><CalendarOutlined /> Select Long Range</Title>}
        visible={isLongRangeModalVisible}
        onCancel={() => setIsLongRangeModalVisible(false)}
        onOk={handleLongRangeSelection}
        width={800}
        footer={[
          <Button key="cancel" onClick={() => setIsLongRangeModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleLongRangeSelection}>
            Apply Selection
          </Button>,
        ]}
      >
        <div style={{ marginBottom: '20px' }}>
          <Title level={4}>Select Years</Title>
          <Row gutter={[16, 16]}>
            {[...Array(10)].map((_, index) => {
              const year = new Date().getFullYear() + index;
              return (
                <Col key={year} span={6}>
                  <Checkbox
                    checked={selectedYears.includes(year)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedYears([...selectedYears, year]);
                        setSelectedMonths(prev => ({
                          ...prev,
                          ...Array.from({ length: 12 }, (_, i) => `${year}-${String(i + 1).padStart(2, '0')}`).reduce((acc, month) => {
                            acc[month] = true;
                            return acc;
                          }, {})
                        }));
                      } else {
                        setSelectedYears(selectedYears.filter(y => y !== year));
                        setSelectedMonths(prev => {
                          const newMonths = { ...prev };
                          Array.from({ length: 12 }, (_, i) => `${year}-${String(i + 1).padStart(2, '0')}`).forEach(month => {
                            delete newMonths[month];
                          });
                          return newMonths;
                        });
                      }
                    }}
                  >
                    <Text strong>{year}</Text>
                  </Checkbox>
                </Col>
              );
            })}
          </Row>
        </div>

        <Divider />

        <div style={{ marginBottom: '20px' }}>
          <Title level={4}>Select Months</Title>
          {selectedYears.map(year => (
            <div key={year}>
              <Title level={5} style={{ marginBottom: '10px' }}>{year}</Title>
              <Row gutter={[16, 16]}>
                {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map((month, index) => {
                  const monthStr = `${year}-${String(index + 1).padStart(2, '0')}`;
                  return (
                    <Col key={monthStr} span={6}>
                      <Checkbox
                        checked={selectedMonths[monthStr] !== false}
                        onChange={(e) => {
                          setSelectedMonths(prev => ({
                            ...prev,
                            [monthStr]: e.target.checked
                          }));
                        }}
                      >
                        <Text>{month}</Text>
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
              <Divider />
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default AdminEditTeacherEditAvailability;
import React, { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { notification, Select, Button, Checkbox, TimePicker, Modal, Row, Col, Typography, Divider, Switch } from "antd";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import "./TeacherEditAvailability.css";
import { updateTeacher } from "../../store/actions/teachersActions";
import moment from 'moment-timezone';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { v4 as uuidv4 } from 'uuid';
import { EditOutlined, DeleteOutlined, PlusOutlined, CalendarOutlined } from '@ant-design/icons';
import { 
  addDays, 
  format, 
  startOfMonth, 
  endOfMonth, 
  startOfYear, 
  endOfYear,
  startOfWeek,
  endOfWeek,
  addMonths,
  isSameMonth,
  isSameDay,
  parseISO,
  isValid
} from 'date-fns';

dayjs.extend(utc);
dayjs.extend(timezone);

const { Title, Text } = Typography;
const { Option } = Select;

const timeZones = moment.tz.names();
const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const CustomDay = ({ day, selectedDates }) => {
  const dateStr = format(day, 'yyyy-MM-dd');
  const isSelected = selectedDates[dateStr];

  return (
    <div className={`custom-day ${isSelected ? 'selected' : ''}`}>
      {format(day, 'd')}
    </div>
  );
};

const styles = {
  yearSection: {
    marginBottom: '20px',
  },
  yearCheckbox: {
    marginRight: '20px',
    marginBottom: '10px',
  },
  monthSection: {
    marginBottom: '20px',
  },
  monthGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '10px',
  },
  yearTitle: {
    marginBottom: '10px',
  },
};

const MiniCalendar = ({ selectedDates, onDateToggle, currentMonth, setCurrentMonth }) => {
  const firstDayOfMonth = startOfMonth(currentMonth);
  const lastDayOfMonth = endOfMonth(currentMonth);
  const startDate = startOfWeek(firstDayOfMonth);
  const endDate = endOfWeek(lastDayOfMonth);

  const dateFormat = "d";
  const rows = [];
  let days = [];
  let day = startDate;

  const changeMonth = (amount) => {
    setCurrentMonth(addMonths(currentMonth, amount));
  };

  while (day <= endDate) {
    for (let i = 0; i < 7; i++) {
      const cloneDay = day;
      const dateStr = format(cloneDay, 'yyyy-MM-dd');
      days.push(
        <div
          className={`calendar-day ${
            !isSameMonth(day, firstDayOfMonth) ? "disabled" :
            isSameDay(day, new Date()) ? "today" : ""
          } ${selectedDates[dateStr] ? "selected" : ""}`}
          key={day}
          onClick={() => onDateToggle(dateStr)}
        >
          <span>{format(day, dateFormat)}</span>
        </div>
      );
      day = addDays(day, 1);
    }
    rows.push(
      <div className="calendar-row" key={day}>
        {days}
      </div>
    );
    days = [];
  }

  return (
    <div className="mini-calendar-container">
      <div className="calendar-header">
        <button onClick={() => changeMonth(-1)}>&lt;</button>
        <h3>{format(currentMonth, 'MMMM yyyy')}</h3>
        <button onClick={() => changeMonth(1)}>&gt;</button>
      </div>
      <div className="calendar-days">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <div className="calendar-day-header" key={day}>{day}</div>
        ))}
      </div>
      <div className="calendar-body">
        {rows}
      </div>
    </div>
  );
};

const TeacherEditAvailability = () => {
  const { id } = useParams();
  const location = useLocation();
  const formData = location.state ? location.state.formData : null;
  const [currentMonth, setCurrentMonth] = useState(formData?.currentMonth ? new Date(formData.currentMonth) : new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [userTimezone, setUserTimezone] = useState(formData?.userTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [hourFormat, setHourFormat] = useState(formData?.hourFormat || '24');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showMiniCalendar, setShowMiniCalendar] = useState(false);
  const [minDate, setMinDate] = useState(new Date());
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDates, setSelectedDates] = useState({});
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
  const [isLongRangeModalVisible, setIsLongRangeModalVisible] = useState(false);
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState({});
  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  });

  const [selectedDays, setSelectedDays] = useState(formData?.selectedDays || {
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false
  });

  const [availability, setAvailability] = useState(() => {
    const initialAvailability = daysOfWeek.reduce((acc, day) => {
      acc[day] = { 
        enabled: formData?.selectedDays?.[day] || false, 
        slots: new Set(), 
        isEditing: false 
      };
      return acc;
    }, {});

    if (formData && formData.Availability && formData.Availability.slots) {
      formData.Availability.slots.forEach(slot => {
        const startDate = dayjs(slot.start).tz(formData.userTimezone || userTimezone);
        const endDate = dayjs(slot.end).tz(formData.userTimezone || userTimezone);
        const dayOfWeek = startDate.format('dddd');
        if (!initialAvailability[dayOfWeek]) {
          initialAvailability[dayOfWeek] = { enabled: true, slots: new Set() };
        }
        const slotKey = `${startDate.format('HH:mm')}-${endDate.format('HH:mm')}`;
        initialAvailability[dayOfWeek].slots.add(slotKey);
      });
    }

    // Convert Sets back to arrays for easier rendering
    Object.keys(initialAvailability).forEach(day => {
      initialAvailability[day].slots = Array.from(initialAvailability[day].slots).map(slotKey => {
        const [fromTime, toTime] = slotKey.split('-');
        return {
          id: uuidv4(),
          fromTime: dayjs(fromTime, 'HH:mm').tz(formData?.userTimezone || userTimezone),
          toTime: dayjs(toTime, 'HH:mm').tz(formData?.userTimezone || userTimezone)
        };
      });
    });
  
    return initialAvailability;
  });

  useEffect(() => {
    if (formData) {
      console.log("Received formData:", formData);
      
      setUserTimezone(formData.userTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone);
      setHourFormat(formData.hourFormat || '24');
      setMinDate(new Date());
      
      if (formData.Availability && formData.Availability.slots) {
        const newSelectedDates = {};
        formData.Availability.slots.forEach(slot => {
          const date = new Date(slot.start);
          if (isValid(date)) {
            const dateStr = format(date, 'yyyy-MM-dd');
            newSelectedDates[dateStr] = true;
          }
        });
        setSelectedDates(newSelectedDates);

        const dateKeys = Object.keys(newSelectedDates);
        if (dateKeys.length > 0) {
          setState({
            selection: {
              startDate: parseISO(dateKeys[0]),
              endDate: parseISO(dateKeys[dateKeys.length - 1]),
              key: 'selection'
            }
          });
        }
      }
      
      if (formData.selectedDays) {
        setSelectedDays(formData.selectedDays);
      }
    }
  }, [formData]);

  const handleCheckboxChange = (day) => {
    setAvailability({
      ...availability,
      [day]: { ...availability[day], enabled: !availability[day].enabled },
    });
  };

  const generateYearRange = (startYear, count) => {
    return Array.from({ length: count }, (_, index) => startYear + index);
  };

  const [yearRange, setYearRange] = useState(() => {
    const currentYear = new Date().getFullYear();
    return generateYearRange(currentYear, 10);
  });

  const handleTimeChange = (day, index, field, newValue) => {
    const newSlots = availability[day].slots.map((slot, i) => {
      if (i === index) {
        if (field === 'fromTime') {
          const toTime = newValue.add(25, 'minute');
          return { ...slot, fromTime: newValue.utc(), toTime: toTime.utc() };
        }
        return { ...slot, [field]: newValue.utc() };
      }
      return slot;
    });
    setAvailability({
      ...availability,
      [day]: { ...availability[day], slots: newSlots },
    });
  };
  
  const handleAddSlot = (day) => {
    setAvailability(prevAvailability => {
      const fromTime = dayjs().utc().hour(12).minute(0);
      const toTime = fromTime.add(25, 'minute');
      const updatedSlots = [...(prevAvailability[day]?.slots || [])];
      updatedSlots.push({ id: uuidv4(), fromTime, toTime });
      return {
        ...prevAvailability,
        [day]: {
          ...prevAvailability[day],
          slots: updatedSlots
        }
      };
    });
  };
  
  const handleAddConsecutiveSlot = (day, index) => {
    setAvailability(prevAvailability => {
      const updatedSlots = [...prevAvailability[day].slots];
      const currentSlot = updatedSlots[index];
      const newFromTime = currentSlot.toTime;
      const newToTime = newFromTime.add(25, 'minute');
      updatedSlots.splice(index + 1, 0, { id: uuidv4(), fromTime: newFromTime, toTime: newToTime });
      return {
        ...prevAvailability,
        [day]: {
          ...prevAvailability[day],
          slots: updatedSlots
        }
      };
    });
  };

  const handleRemoveSlot = (day, index) => {
    const newSlots = availability[day].slots.filter((_, i) => i !== index);
    setAvailability({
      ...availability,
      [day]: { ...availability[day], slots: newSlots },
    });
  };

  const handleEditClick = (day) => {
    setAvailability({
      ...availability,
      [day]: { ...availability[day], isEditing: !availability[day].isEditing },
    });
  };

  const handleRangeChange = (item) => {
    const newSelectedDates = { ...selectedDates };
    let currentDate = new Date(item.selection.startDate);
    const endDate = new Date(item.selection.endDate);
  
    while (currentDate <= endDate) {
      const dateStr = format(currentDate, 'yyyy-MM-dd');
      const dayName = format(currentDate, 'EEEE');
      newSelectedDates[dateStr] = selectedDays[dayName];
      currentDate = addDays(currentDate, 1);
    }
  
    setSelectedDates(newSelectedDates);
    setState({ ...state, ...item });
  };

  const handleDateToggle = (dateStr) => {
    const newSelectedDates = {
      ...selectedDates,
      [dateStr]: !selectedDates[dateStr]
    };
    setSelectedDates(newSelectedDates);

    const selectedDatesList = Object.keys(newSelectedDates).filter(date => newSelectedDates[date]);
    if (selectedDatesList.length > 0) {
      const startDate = new Date(selectedDatesList[0]);
      const endDate = new Date(selectedDatesList[selectedDatesList.length - 1]);
      setState({
        ...state,
        selection: {
          startDate,
          endDate,
          key: 'selection'
        }
      });
    } else {
      setState({
        ...state,
        selection: {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
      });
    }
  };

  const handleDayCheckboxChange = (day) => {
    setSelectedDays(prevDays => {
      const newDays = { ...prevDays, [day]: !prevDays[day] };
      
      setAvailability(prevAvailability => ({
        ...prevAvailability,
        [day]: { 
          ...prevAvailability[day], 
          enabled: newDays[day]
        }
      }));
  
      setSelectedDates(prevDates => {
        const newDates = { ...prevDates };
        const startDate = state.selection.startDate;
        const endDate = state.selection.endDate;
        let currentDate = new Date(startDate);
  
        while (currentDate <= endDate) {
          const dateStr = format(currentDate, 'yyyy-MM-dd');
          const dayOfWeek = format(currentDate, 'EEEE');
          
          if (dayOfWeek === day) {
            newDates[dateStr] = newDays[day];
          }
  
          currentDate = addDays(currentDate, 1);
        }
  
        return newDates;
      });
  
      return newDays;
    });
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = async () => {
    const teacherId = id;
    try {
      const availabilitySlots = [];
  
      Object.entries(selectedDates).forEach(([date, isSelected]) => {
        if (isSelected) {
          const dayOfWeek = format(new Date(date), 'EEEE');
          if (selectedDays[dayOfWeek] && availability[dayOfWeek]?.slots.length > 0) {
            availability[dayOfWeek].slots.forEach(slot => {
              // Create a date in the user's timezone
              let slotDate = dayjs.tz(date, userTimezone);
              
              // Parse the from and to times in the user's timezone
              const fromTime = dayjs.tz(`${date} ${slot.fromTime.format('HH:mm')}`, userTimezone);
              const toTime = dayjs.tz(`${date} ${slot.toTime.format('HH:mm')}`, userTimezone);
  
              // Convert to UTC and format without seconds and 'Z'
              const startDateTime = fromTime.utc().format('YYYY-MM-DDTHH:mm');
              const endDateTime = toTime.utc().format('YYYY-MM-DDTHH:mm');
  
              availabilitySlots.push({
                start: startDateTime,
                end: endDateTime
              });
            });
          }
        }
      });
  
      const availabilityData = {
        slots: availabilitySlots
      };
  
      const updatedData = {
        ...formData,
        Availability: availabilityData,
        selectedDays,
        userTimezone,
      };
  
      await dispatch(updateTeacher({
        teacherId,
        updatedData,
      }));
  
      // Update local state
      setAvailability(prevAvailability => {
        const newAvailability = { ...prevAvailability };
        availabilitySlots.forEach(slot => {
          const slotDate = dayjs(slot.start).tz(userTimezone);
          const dayOfWeek = slotDate.format('dddd');
          if (!newAvailability[dayOfWeek]) {
            newAvailability[dayOfWeek] = { enabled: true, slots: [] };
          }
          newAvailability[dayOfWeek].slots.push({
            id: uuidv4(),
            fromTime: dayjs(slot.start).tz(userTimezone),
            toTime: dayjs(slot.end).tz(userTimezone)
          });
        });
        return newAvailability;
      });
  
      notification.success({
        message: 'Changes Saved',
        description: 'Your availability has been successfully updated.',
      });
  
      setIsModalVisible(false);
      navigate("/Teacher-dashboard/profile");
    } catch (error) {
      console.error("Error editing teacher:", error);
      notification.error({
        message: 'Submission Error',
        description: 'There was an error updating your availability. Please try again.',
      });
    }
  };

  const handleLongRangeSelection = () => {
    const newSelectedDates = {};
    selectedYears.forEach(year => {
      const startOfYear = new Date(year, 0, 1);
      const endOfYear = new Date(year, 11, 31);
      let currentDate = startOfYear;

      while (currentDate <= endOfYear) {
        const dateStr = format(currentDate, 'yyyy-MM-dd');
        const monthStr = format(currentDate, 'yyyy-MM');
        const dayName = format(currentDate, 'EEEE');

        if (selectedMonths[monthStr] !== false) {
          newSelectedDates[dateStr] = selectedDays[dayName];
        }

        currentDate = addDays(currentDate, 1);
      }
    });

    setSelectedDates(newSelectedDates);
    setState({
      ...state,
      selection: {
        startDate: new Date(Math.min(...Object.keys(newSelectedDates).map(date => new Date(date)))),
        endDate: new Date(Math.max(...Object.keys(newSelectedDates).map(date => new Date(date)))),
        key: 'selection'
      }
    });
    setIsLongRangeModalVisible(false);
  };

  const handleDeleteEntireAvailability = () => {
    setSelectedDates({});
    setAvailability(
      daysOfWeek.reduce((acc, day) => {
        acc[day] = { enabled: false, slots: [], isEditing: false };
        return acc;
      }, {})
    );
    setSelectedDays(
      daysOfWeek.reduce((acc, day) => {
        acc[day] = false;
        return acc;
      }, {})
    );
    setIsDeleteConfirmVisible(false);
  };

  return (
    <>
      <div className="styled-container">
        <div className="calendar-container">
          <h2>Edit Teacher Availability</h2>
          
          <div className="day-selection">
            {Object.keys(selectedDays).map(day => (
              <div key={day} className="day-switch">
                <Switch
                  checked={selectedDays[day]}
                  onChange={() => handleDayCheckboxChange(day)}
                  className="day-switch-toggle"
                />
                <span className={selectedDays[day] ? 'day-label' : 'day-label-disabled'}>
                  {day}
                </span>
              </div>
            ))}
          </div>
          
          <DateRangePicker
            onChange={item => handleRangeChange(item)}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[state.selection]}
            direction="horizontal"
            minDate={minDate}
            dayContentRenderer={day => <CustomDay day={day} selectedDates={selectedDates} />}
          />
          
          <div className="mini-calendar-switch">
            <Switch
              checked={showMiniCalendar}
              onChange={setShowMiniCalendar}
              checkedChildren="Edit Specific Dates"
              unCheckedChildren="Edit Specific Dates"
            />
          </div>
          
          {showMiniCalendar && (
            <MiniCalendar 
              selectedDates={selectedDates}
              onDateToggle={handleDateToggle}
              currentMonth={currentMonth}
              setCurrentMonth={setCurrentMonth}
            />
          )}
          
          <div className="action-buttons">
            <Button 
              icon={<CalendarOutlined />}
              onClick={() => setIsLongRangeModalVisible(true)}
              className="select-long-range-button"
            >
              Select Long Range
            </Button>
            <Button 
              onClick={() => setIsModalVisible(true)}
              type="primary"
              className="set-availability-button"
            >
              Set Availability for Selected Dates
            </Button>
            <Button 
              onClick={() => setIsDeleteConfirmVisible(true)}
              type="danger"
              className="delete-availability-button"
            >
              Delete Entire Availability
            </Button>
          </div>
        </div>
      </div>

      <Modal
        title="Weekly Availability"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button key="cancel" onClick={handleModalClose}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Submit
          </Button>,
        ]}
        width={800}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="modal-content">
            <div className="timezone-selector">
              <label>Timezone availability:</label>
              <Select
                style={{ width: 200 }}
                value={userTimezone}
                onChange={setUserTimezone}
                showSearch
                optionFilterProp="children" 
                placeholder="Select a timezone" 
                filterOption={(input, option) =>
                  (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                } 
              >
                {timeZones.map(tz => (
                  <Option key={tz} value={tz}>
                    {tz}
                  </Option>
                ))}
              </Select>
            </div>
            {daysOfWeek.map((day) => (
              <div key={day} className="day-availability">
                <div className="day-switch">
                  <Switch
                    checked={selectedDays[day]}
                    onChange={() => handleDayCheckboxChange(day)}
                    className="day-switch-toggle"
                  />
                  <span className={selectedDays[day] ? 'day-label' : 'day-label-disabled'}>
                    {day}
                  </span>
                </div>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => handleEditClick(day)}
                  type={availability[day]?.isEditing ? "primary" : "default"}
                  className="edit-button"
                  disabled={!selectedDays[day]}
                  style={{ 
                    opacity: selectedDays[day] ? 1 : 0.5,
                    cursor: selectedDays[day] ? 'pointer' : 'not-allowed'
                  }}
                >
                  {availability[day]?.isEditing ? 'Save' : 'Edit'}
                </Button>
                {selectedDays[day] && availability[day] && (
          <div className="time-slots">
            {(availability[day].slots || []).map((slot, index) => (
              <div key={slot.id} className="time-slot">
                {availability[day]?.isEditing ? (
                  <>
                    <TimePicker
                      value={slot.fromTime}
                      onChange={(time) => handleTimeChange(day, index, 'fromTime', time)}
                      format={hourFormat === '24' ? 'HH:mm' : 'h:mm a'}
                      minuteStep={5}
                    />
                    <TimePicker
                      value={slot.toTime}
                      onChange={(time) => handleTimeChange(day, index, 'toTime', time)}
                      format={hourFormat === '24' ? 'HH:mm' : 'h:mm a'}
                      minuteStep={5}
                      disabled={true}
                    />
                    <Button
                      icon={<PlusOutlined />}
                      onClick={() => handleAddConsecutiveSlot(day, index)}
                      className="add-consecutive-slot-button"
                      disabled={index !== availability[day].slots.length - 1}
                    >
                      Add Consecutive
                    </Button>
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => handleRemoveSlot(day, index)}
                      danger
                      className="delete-button"
                    />
                  </>
                ) : (
                  <span>
                    {slot.fromTime.format(hourFormat === '24' ? 'HH:mm' : 'h:mm a')} - {slot.toTime.format(hourFormat === '24' ? 'HH:mm' : 'h:mm a')}
                  </span>
                )}
              </div>
            ))}
            {availability[day]?.isEditing && (
              <Button icon={<PlusOutlined />} onClick={() => handleAddSlot(day)} className="add-slot-button">
                Add Slot
              </Button>
            )}
          </div>
        )}
      </div>
    ))}
          </div>
        </LocalizationProvider>
      </Modal>

      <Modal
        title="Confirm Deletion"
        visible={isDeleteConfirmVisible}
        onCancel={() => setIsDeleteConfirmVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsDeleteConfirmVisible(false)}>
            Cancel
          </Button>,
          <Button key="delete" type="danger" onClick={handleDeleteEntireAvailability}>
            Delete
          </Button>,
        ]}
      >
        <p>Are you sure you want to delete the entire availability? This action cannot be undone.</p>
      </Modal>

      <Modal
        title={<Title level={3}><CalendarOutlined /> Select Long Range</Title>}
        visible={isLongRangeModalVisible}
        onCancel={() => setIsLongRangeModalVisible(false)}
        onOk={handleLongRangeSelection}
        width={800}
        footer={[
          <Button key="cancel" onClick={() => setIsLongRangeModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleLongRangeSelection}>
            Apply Selection
          </Button>,
        ]}
      >
        <div style={styles.yearSection}>
          <Title level={4}>Select Years</Title>
          <Row gutter={[16, 16]}>
            {yearRange.map(year => (
              <Col key={year} span={6}>
                <Checkbox
                  checked={selectedYears.includes(year)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedYears([...selectedYears, year]);
                      setSelectedMonths(prev => ({
                        ...prev,
                        ...Array.from({ length: 12 }, (_, i) => `${year}-${String(i + 1).padStart(2, '0')}`).reduce((acc, month) => {
                          acc[month] = true;
                          return acc;
                        }, {})
                      }));
                    } else {
                      setSelectedYears(selectedYears.filter(y => y !== year));
                      setSelectedMonths(prev => {
                        const newMonths = { ...prev };
                        Array.from({ length: 12 }, (_, i) => `${year}-${String(i + 1).padStart(2, '0')}`).forEach(month => {
                          delete newMonths[month];
                        });
                        return newMonths;
                      });
                    }
                  }}
                  style={styles.yearCheckbox}
                >
                  <Text strong>{year}</Text>
                </Checkbox>
              </Col>
            ))}
          </Row>
        </div>
        <Divider />
        <div style={styles.monthSection}>
          <Title level={4}>Select Months</Title>
          {selectedYears.map(year => (
            <div key={year}>
              <Title level={5} style={styles.yearTitle}>{year}</Title>
              <div style={styles.monthGrid}>
                {Array.from({ length: 12 }, (_, i) => {
                  const monthStr = `${year}-${String(i + 1).padStart(2, '0')}`;
                  return (
                    <Checkbox
                      key={monthStr}
                      checked={selectedMonths[monthStr] !== false}
                      onChange={(e) => {
                        setSelectedMonths(prev => ({
                          ...prev,
                          [monthStr]: e.target.checked
                        }));
                      }}
                    >
                      <Text>{format(new Date(year, i, 1), 'MMM')}</Text>
                    </Checkbox>
                  );
                })}
              </div>
              <Divider />
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default TeacherEditAvailability;
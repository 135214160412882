import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axiosconfig";
import { websocketConnected, websocketMessageReceived, websocketDisconnected } from '../slices/studentsSlice';

import { toast } from "react-toastify";

// ACTIONS : api calls
export const fetchAllstudents = createAsyncThunk(
  "student/fetchAllStudent",
  async () => {
    const response = await axios.get(`FetchAll_students`);
    return response.data.studentslist;
  }
);

export const Signup_Student = createAsyncThunk(
  "student/Signup_Student",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`Signup_Student`, data);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const FindUserByEmail = createAsyncThunk(
  "student/FindUserByEmail",
  async (data) => {
    try {
      const response = await axios.post(`FindUserByEmail`, data);
      // console.log(response);
      return response.data;
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);

export const MatchOTP = createAsyncThunk("student/MatchOTP", async (data) => {
  try {
    const response = await axios.post(`MatchOTP`, data);
    // console.log(response);
    return response.data;
  } catch (error) {
    console.log(error.response.data.message);
    return error.response.data.message;
  }
});

export const Reset_Password = createAsyncThunk(
  "auth/Reset_Password",
  async (data) => {
    try {
      const response = await axios.post(`Reset_Password`, data);
      // console.log(response);
      return response.data;
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);

export const async_loaduser = createAsyncThunk(
  "student/async_loaduser",
  async (_, { rejectWithValue }) => {
    try {
      console.log("Sending request to /api/me");
      const response = await axios.get(`me`);
      console.log("Response received:", response);
      return response.data.user;
    } catch (error) {
      console.error("Error in async_loaduser:", error);
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

export const async_removeuser = createAsyncThunk(
  "student/async_removeuser",
  async () => {
    const response = await axios.get(`signout`);
    // console.log(response)
    return response;
  }
);

export const fetchStudentDetails = createAsyncThunk(
  "student/fetchStudentDetails",
  async (id) => {
    const response = await axios.get(`fetchStudentDetails/${id}`);
    // console.log(response)
    return response.data.StudentDetails;
  }
);

export const DeleteStudent = createAsyncThunk(
  "student/DeleteStudent",
  async (student_ID) => {
    try {
      const response = await axios.get(`Delete_student/${student_ID}`);
      // console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const updateStudent = createAsyncThunk(
  "student/updateStudent",
  async ({ student_ID, updatedData }) => {
    console.log(student_ID, "studentID");
    console.log(updatedData, "updatedData");
    try {
      const response = await axios.post(
        `Update_Student/${student_ID}`,
        updatedData
      );
      // console.log(response.data);
      return response.data;
      // return { student_ID, updatedData };
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const Signin_user = createAsyncThunk(
  "student/Signin_user",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`Signin_user`, data);
      console.log("Login response:", response.data);
      
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        console.log("Token saved to localStorage:", response.data.token);
      } else {
        console.log("No token received in response");
      }
      
      return response.data;
    } catch (error) {
      console.error("Login error:", error.response?.data);
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

export const Signup_Student_By_Admin = createAsyncThunk(
  "student/Signup_Student_By_Admin",
  async (data) => {
    try {
      console.log(data)
        const response = await axios.post(`Signup_Student_By_Admin`, data);
        return response;
    } catch (error) {
       console.log(error.response.data.message);
       return error.response.data.message;
    }
  }
);



export const Signup_Student_With_Booking = createAsyncThunk(
  "student/Signup_Student_With_Booking",
  async (data) => {
    console.log(data)
    try {
      const response = await axios.post(`Signup_Student_With_Booking`, data);
      // console.log(response);
      return response;
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);



export const Create_Owncloud_Action = createAsyncThunk(
  "student/Create_Owncloud_Action",
  async (data) => {
    // console.log(data)
    try {
      const response = await axios.post(`Create_Owncloud_Account`, data);
      // console.log(response);
      return response;
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);



export const SearchStudentbyUsername = createAsyncThunk(
  "student/SearchStudentbyUsername",
  async (input) => {
    // console.log(input)
    try {
      const response = await axios.post(`SearchStudentbyUsername/${input}`);
      // console.log(response);
      return response;
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);

export const Configure_RecieveEmail = createAsyncThunk(
  "student/Configure_RecieveEmail",
  async (Email)=> {
    console.log(Email)

    try {
      const response = await axios.get(`Configure_RecieveEmail/${Email}`);
      console.log(response);
      toast.success(response.data.message)
      // return response;
    } catch (error) {
      toast.error(error.response.data.message)
      console.log(error.response.data.message);
      return error.response.data.message;
    }
  }
);

export const listDriveFiles = createAsyncThunk(
  "student/listDriveFiles",
  async (folderId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`list-drive-files/${folderId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const downloadDriveFile = createAsyncThunk(
  "student/downloadDriveFile",
  async (fileId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`download-drive-file/${fileId}`, {
        responseType: 'blob'
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updatePhoneNumber = createAsyncThunk(
  "student/updatePhoneNumber",
  async ({ userId, Phone_Number }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/update-phone-number', { userId, Phone_Number });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const connectWebSocket = createAsyncThunk(
  'student/connectWebSocket',
  async (_, { dispatch }) => {
    const socket = new WebSocket('ws://localhost:3000'); // Replace with your WebSocket server URL

    socket.onopen = () => {
      console.log('WebSocket connected');
      dispatch(websocketConnected());
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      dispatch(websocketMessageReceived(data));
    };

    socket.onclose = () => {
      console.log('WebSocket disconnected');
      dispatch(websocketDisconnected());
    };

    return socket;
  }
);

export const sendWebSocketMessage = createAsyncThunk(
  'student/sendWebSocketMessage',
  async (message, { getState }) => {
    const { socket } = getState().students;
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(message));
    } else {
      throw new Error('WebSocket is not connected');
    }
  }
);

export const disconnectWebSocket = createAsyncThunk(
  'student/disconnectWebSocket',
  async (_, { getState }) => {
    const { socket } = getState().students;
    if (socket) {
      socket.close();
    }
  }
);

export const updateStudentTimezone = createAsyncThunk(
  "student/updateStudentTimezone",
  async ({ userId, Timezone }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/update-student-timezone', { userId, Timezone });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
import React, { useRef, useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { fetchAllbookings } from "../../store/actions/bookingActions";
import Loader from "../Loader"; 

const Room = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bookings = useSelector((state) => state.bookings.Allbookinglist);
  const user = useSelector((state) => state.students.user);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const filteredBooking = useMemo(() => 
    bookings.find((booking) => booking._id === id),
    [bookings, id]
  );
  const jitsiContainerRef = useRef(null);
  const [jitsiAPI, setJitsiAPI] = useState(null);
  const isInitialized = useRef(false);
  const [isModerator, setIsModerator] = useState(false);
  const [useApp, setUseApp] = useState(false); // New state for app selection

  const roleInfo = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const roleInfoParam = searchParams.get('roleInfo');
    return roleInfoParam ? JSON.parse(decodeURIComponent(roleInfoParam)) : null;
  }, [location.search]);

  const checkModeratorStatus = useCallback(() => {
    return roleInfo && roleInfo.role === 'teacher';
  }, [roleInfo]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchAllbookings());
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load booking information. Please try again later.");
      }
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setIsModerator(checkModeratorStatus());
  }, [checkModeratorStatus]);

  const formatStudentName = useCallback((student) => {
    if (!student || !student.Username) return 'N/A';
    const [firstName, ...lastNameParts] = student.Username.split(' ');
    const lastInitial = lastNameParts.length > 0 ? lastNameParts[0][0] : '';
    return `${firstName} ${lastInitial}${lastInitial ? '.' : ''}`;
  }, []);

  const initJitsi = useCallback(() => {
    if (jitsiContainerRef.current && jitsiContainerRef.current.childNodes.length === 0 && !isInitialized.current) {
      isInitialized.current = true;
      const domain = "meet.speakable.online";
      const options = {
        roomName: filteredBooking._id.toString(),
        width: "100%",
        height: "100%",
        parentNode: jitsiContainerRef.current,
        userInfo: {
          displayName: formatStudentName(user), 
          email: user.email,
          role: roleInfo ? roleInfo.role : 'participant'
        },
        interfaceConfigOverwrite: {
          TOOLBAR_BUTTONS: [
            "microphone", "camera", "closedcaptions", "desktop", "fullscreen",
            "fodeviceselection", "hangup", "profile", "chat", "recording",
            "livestreaming", "etherpad", "sharedvideo", "settings", "raisehand",
            "videoquality", "filmstrip", "invite", "feedback", "stats",
            "shortcuts", "tileview", "videobackgroundblur", "download", "help",
            "mute-everyone", "security"
          ],
          TOOLBAR_BUTTONS_ONLY_MODERATOR: [
            "mute-everyone",
            "security"
          ],
          DISPLAY_WELCOME_PAGE_CONTENT: false,
          SHOW_JITSI_WATERMARK: false,
        },
        configOverwrite: {
          disableDeepLinking: true,
          enableUserRolesBasedOnToken: false, // Set to false since you're not using tokens
          useHostPageLocalStorage: true,
          prejoinPageEnabled: false,
        }
      };

      const api = new window.JitsiMeetExternalAPI(domain, options);
      setJitsiAPI(api);

      api.on("videoConferenceJoined", () => {
        if (isModerator) {
          api.executeCommand('setRole', 'moderator');
        } else {
          api.executeCommand('setRole', 'participant');
        }
        api.executeCommand('displayName', formatStudentName(user));
      });

      api.on("videoConferenceLeft", () => {
        navigate('/');
      });

      api.addListener('readyToClose', () => {
        navigate('/');
      });
    }
  }, [filteredBooking, user, navigate, isModerator, roleInfo, formatStudentName]);

  useEffect(() => {
    if (!window.JitsiMeetExternalAPI) {
      const script = document.createElement("script");
      script.src = "https://meet.speakable.online/external_api.js";
      script.async = true;
      script.onload = () => {
        if (filteredBooking && user) {
          initJitsi();
        }
      };
      document.body.appendChild(script);
    } else if (filteredBooking && user) {
      initJitsi();
    }
  }, [filteredBooking, user, initJitsi]);

  useEffect(() => {
    return () => {
      if (jitsiAPI) {
        jitsiAPI.dispose();
        setJitsiAPI(null);
        isInitialized.current = false;
      }
    };
  }, [jitsiAPI]);

  const handleToggleApp = () => {
    setUseApp(prev => !prev); // Toggle between app and browser
  };

  const joinMeeting = () => {
    const roomName = filteredBooking._id.toString();
    const url = `https://meet.speakable.online/${roomName}`;

    if (useApp) {
      // Open Jitsi in the application
      window.open(`jitsi:${roomName}`); // Assuming `jitsi:` is a valid scheme for Jitsi app
    } else {
      // Open in the browser
      window.open(url, "_blank");
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!filteredBooking || !user) {
    return <Loader loading={true} text="Loading... Please wait while we set up your meeting." fullScreen={true} />;
  }

  return (
    <div style={{ width: '100vw', height: '100vh', position: 'absolute', top: 0, left: 0 }}>
      {isLoading && (
        <Loader loading={true} text="Joining the meeting... Please wait." fullScreen={true} />
      )}
      <div ref={jitsiContainerRef} style={{ width: '100%', height: '100%' }} />
      
      <button onClick={handleToggleApp} style={{ position: 'absolute', top: 10, left: 10 }}>
        {useApp ? "Use Browser" : "Use Jitsi App"}
      </button>

      <button onClick={joinMeeting} style={{ position: 'absolute', top: 50, left: 10 }}>
        Join Meeting
      </button>

      {isModerator && (
        <div style={{ position: 'absolute', top: 10, right: 10, background: 'rgba(0,0,0,0.5)', color: 'white', padding: '5px 10px', borderRadius: '5px' }}>
          Teacher (Moderator)
        </div>
      )}
      {!isModerator && (
        <div style={{ position: 'absolute', top: 10, right: 10, background: 'rgba(0,0,0,0.5)', color: 'white', padding: '5px 10px', borderRadius: '5px' }}>
          Student (Participant)
        </div>
      )}
    </div>
  );
};

export default Room;

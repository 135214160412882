import React, { useState, useCallback, useMemo, useEffect } from 'react';
import moment from 'moment-timezone';
import { Modal, Button } from 'antd';

const styles = {
  calendar: {
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    padding: '16px',
    width: '100%',
    maxWidth: '400px',
    margin: '0 auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  monthYear: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#333',
  },
  button: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '20px',
    color: '#666',
  },
  weekdays: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gap: '4px',
    textAlign: 'center',
    marginBottom: '8px',
  },
  weekday: {
    fontSize: '12px',
    fontWeight: '500',
    color: '#666',
  },
  days: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gap: '4px',
  },
  day: {
    width: '100%',
    aspectRatio: '1 / 1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    borderRadius: '50%',
    transition: 'background-color 0.2s',
  },
  slotContainer: {
    marginTop: '16px',
    maxHeight: '200px',
    overflowY: 'auto',
  },
  slot: {
    padding: '8px',
    margin: '4px 0',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
  },
  statusIndicator: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '4px',
  },
};

const statusColors = {
  Scheduled: '#5C7153',
  Cancelled: '#F44336',
  Rescheduled: '#FFC107',
  Completed: '#2196F3',
  Available: '#4CAF50',
  OtherStudentBooking: '#FF9800',
};

const MobileCalendar = ({
  selectedTeacherId,
  availability,
  selectedSlots,
  handleSlotToggle,
  selectedTimezone,
  renderDateTime,
  freeHours,
  usedHours,
  t
}) => {
  const [currentDate, setCurrentDate] = useState(moment().tz(selectedTimezone));
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDateSlots, setSelectedDateSlots] = useState([]);

  useEffect(() => {
    console.log("MobileCalendar props:", {
      selectedTeacherId,
      availability: availability.length,
      selectedSlots: Object.keys(selectedSlots).length,
      selectedTimezone,
      freeHours,
      usedHours
    });
  }, [selectedTeacherId, availability, selectedSlots, selectedTimezone, freeHours, usedHours]);

  const prevMonth = () => {
    setCurrentDate(currentDate.clone().subtract(1, 'month').startOf('month'));
  };

  const nextMonth = () => {
    setCurrentDate(currentDate.clone().add(1, 'month').startOf('month'));
  };

  const getSlotsForDate = useCallback((date) => {
    return availability.filter(slot => 
      moment(slot.start).tz(selectedTimezone).isSame(date, 'day')
    ).sort((a, b) => moment(a.start).diff(moment(b.start)));
  }, [availability, selectedTimezone]);

  const availableDates = useMemo(() => {
    const dates = new Set();
    availability.forEach(slot => {
      const date = moment(slot.start).tz(selectedTimezone).format('YYYY-MM-DD');
      dates.add(date);
    });
    console.log('Available dates:', dates);
    return dates;
  }, [availability, selectedTimezone]);

  const handleDateClick = (day) => {
    const clickedDate = currentDate.clone().date(day);
    setCurrentDate(clickedDate);
    
    const slotsForDate = getSlotsForDate(clickedDate);
    console.log(`Slots for ${clickedDate.format('YYYY-MM-DD')}:`, slotsForDate);
    
    setSelectedDateSlots(slotsForDate);
    setIsModalVisible(true);
  };

  const isSlotSelected = useCallback((slot) => {
    return Object.values(selectedSlots).some(
      (selectedSlot) => moment(selectedSlot.start).isSame(slot.start) && moment(selectedSlot.end).isSame(slot.end)
    );
  }, [selectedSlots]);

  const renderSlot = (slot) => {
    const status = slot.extendedProps.status;
    const isBooked = status !== 'Available';
    const isSelected = isSlotSelected(slot);
    const isFreeHour = isSelected && usedHours < freeHours;
    
    const slotStyle = {
      ...styles.slot,
      backgroundColor: isSelected ? '#4CAF50' : statusColors[status] || statusColors.Available,
      color: status === 'Available' ? '#000' : '#fff',
      opacity: isFreeHour ? 0.7 : 1,
    };

    return (
      <div
        key={slot.id}
        style={slotStyle}
        onClick={() => !isBooked && handleSlotToggle(slot)}
      >
        <span style={{
          ...styles.statusIndicator,
          backgroundColor: statusColors[status]
        }}></span>
        {renderDateTime(slot.start)} - {renderDateTime(slot.end)}
        <span style={{ marginLeft: '8px', fontSize: '0.8em' }}>{slot.title}</span>
        {isFreeHour && <span style={{ marginLeft: '8px', fontSize: '0.8em' }}>{t('MobileCalendar.FreeHour')}</span>}
      </div>
    );
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  return (
    <div style={styles.calendar}>
      <div style={styles.header}>
        <button onClick={prevMonth} style={styles.button}>&lt;</button>
        <h2 style={styles.monthYear}>
          {currentDate.format('MMMM YYYY')}
        </h2>
        <button onClick={nextMonth} style={styles.button}>&gt;</button>
      </div>
      <div style={styles.weekdays}>
        {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map((day) => (
          <div key={day} style={styles.weekday}>{day}</div>
        ))}
      </div>
      <div style={styles.days}>
        {[...Array(currentDate.startOf('month').day()).keys()].map((_, index) => (
          <div key={`empty-${index}`}></div>
        ))}
        {[...Array(currentDate.daysInMonth()).keys()].map((_, index) => {
          const day = index + 1;
          const date = currentDate.clone().date(day);
          const dateStr = date.format('YYYY-MM-DD');
          const hasSlots = availableDates.has(dateStr);
          return (
            <button
              key={day}
              onClick={() => handleDateClick(day)}
              style={{
                ...styles.day,
                backgroundColor: hasSlots ? '#e6ffe6' : 'transparent',
                color: currentDate.date() === day ? '#4CAF50' : 'inherit',
                fontWeight: currentDate.date() === day ? 'bold' : 'normal',
              }}
            >
              {day}
            </button>
          );
        })}
      </div>
      
      {availableDates.size === 0 && (
        <p style={{ textAlign: 'center', marginTop: '20px' }}>
          {t('MobileCalendar.NoAvailableDates')}
        </p>
      )}
      
      <Modal
        title={t('StudentWallet.AvailableHours')}
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button key="close" onClick={handleModalClose}>
            {t('YourMaterialModal2.close')}
          </Button>
        ]}
      >
        <div style={styles.slotContainer}>
          {selectedDateSlots.length > 0 ? (
            selectedDateSlots.map(renderSlot)
          ) : (
            <p>{t('MobileCalendar.NoSlotsAvailable')}</p>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default MobileCalendar;
import React, { useState } from 'react';
import './SmallCalendar.css';

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

const SmallCalendar = ({ onChange, availableDates }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());

  const getDaysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();
  const getFirstDayOfMonth = (year, month) => new Date(year, month, 1).getDay();

  const getPreviousMonth = (date) => new Date(date.getFullYear(), date.getMonth() - 1, 1);
  const getNextMonth = (date) => new Date(date.getFullYear(), date.getMonth() + 1, 1);

  const handlePrevMonth = () => {
    setCurrentDate(prevDate => new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(prevDate => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1));
  };

  const handleDateClick = (day) => {
    const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    setSelectedDate(newDate);
    onChange(newDate);
  };

  const isDateAvailable = (year, month, day) => {
    const dateString = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    return availableDates.hasOwnProperty(dateString);
  };

  const renderCalendarDays = () => {
    const daysInMonth = getDaysInMonth(currentDate.getFullYear(), currentDate.getMonth());
    const firstDayOfMonth = getFirstDayOfMonth(currentDate.getFullYear(), currentDate.getMonth());
    const days = [];

    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`empty-${i}`} className="calendar-day empty"></div>);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const isSelected = day === selectedDate.getDate() &&
                         currentDate.getMonth() === selectedDate.getMonth() &&
                         currentDate.getFullYear() === selectedDate.getFullYear();
      const isToday = day === new Date().getDate() &&
                      currentDate.getMonth() === new Date().getMonth() &&
                      currentDate.getFullYear() === new Date().getFullYear();
      const isAvailable = isDateAvailable(currentDate.getFullYear(), currentDate.getMonth(), day);

      days.push(
        <div
          key={day}
          onClick={() => handleDateClick(day)}
          className={`calendar-day ${isSelected ? 'selected' : ''} ${isToday ? 'today' : ''} ${isAvailable ? 'available' : ''}`}
        >
          {day}
        </div>
      );
    }

    return days;
  };

  return (
    <div className="custom-calendar">
      <div className="calendar-header">
        <div className="current-date">
          <div className="day">{daysOfWeek[selectedDate.getDay()]}</div>
          <div className="date">{selectedDate.getDate()}</div>
        </div>
        <div className="month-year">
          <div>{months[selectedDate.getMonth()]}</div>
          <div>{selectedDate.getFullYear()}</div>
        </div>
      </div>
      <div className="calendar-body">
        <div className="month-navigation">
          <button onClick={handlePrevMonth} className="nav-button">&#8249;</button>
          <div className="current-month">{months[currentDate.getMonth()]} {currentDate.getFullYear()}</div>
          <button onClick={handleNextMonth} className="nav-button">&#8250;</button>
        </div>
        <div className="weekdays">
          {daysOfWeek.map(day => (
            <div key={day} className="weekday">{day}</div>
          ))}
        </div>
        <div className="days">
          {renderCalendarDays()}
        </div>
      </div>
      <div className="calendar-footer">
        <span>{months[getPreviousMonth(currentDate).getMonth()]}</span>
        <span>{months[currentDate.getMonth()]}</span>
        <span>{months[getNextMonth(currentDate).getMonth()]}</span>
      </div>
    </div>
  );
};

export default SmallCalendar;
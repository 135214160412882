import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAllbookings,
  Add_booking,
  Updatebooking,
  Deletebooking,
  GetBookingsByStudentID,
  GetBookingsByTeacherID,
  GetExistingTeacherAvailability,
  GetExistingTeacherAvailability__oF_Package,
  SearchBookingbyStudentUsername,
  fetchExistingBookings,
  fetchBookingsExcludingStudent
} from "../actions/bookingActions";
import { toast } from "react-toastify";

const initialState = {
  loading: false,
  bookinglist: [],
  Allbookinglist: [],
  StudentID_Booking: [],
  Teacher_Bookings: [],
  Teacher_Availabile_Booking_Slots: [],
  existingBookings: [],
  error: null,
};

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllbookings.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllbookings.fulfilled, (state, action) => {
        state.bookinglist = action.payload;
        state.Allbookinglist = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllbookings.rejected, (state) => {
        state.loading = false;
      })
      .addCase(Add_booking.fulfilled, (state, action) => {
        state.Allbookinglist.push(action.payload.newBooking);
        state.loading = false;
      })
      .addCase(Add_booking.rejected, (state) => {
        state.loading = false;
      })
      .addCase(Deletebooking.fulfilled, (state, action) => {
        state.loading = false;
        state.Allbookinglist = state.Allbookinglist.filter(
          (Booking) => Booking._id !== action.payload
        );
      })
      .addCase(Deletebooking.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(Updatebooking.fulfilled, (state, action) => {
        const { BookingID, updatedData } = action.payload;
        const updatedBookingIndex = state.Allbookinglist.findIndex(
          (Booking) => Booking._id === BookingID
        );

        if (updatedBookingIndex !== -1) {
          state.Allbookinglist[updatedBookingIndex] = {
            ...state.Allbookinglist[updatedBookingIndex],
            ...updatedData,
          };
        }
      })
      .addCase(Updatebooking.rejected, (state) => {
        state.loading = false;
      })
      .addCase(GetBookingsByStudentID.fulfilled, (state, action) => {
        state.loading = false;
        state.StudentID_Booking = action.payload;
      })
      .addCase(GetBookingsByStudentID.rejected, (state) => {
        state.loading = false;
      })
      .addCase(GetBookingsByTeacherID.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetBookingsByTeacherID.fulfilled, (state, action) => {
        state.Teacher_Bookings = action.payload;
        state.loading = false;
      })
      .addCase(GetBookingsByTeacherID.rejected, (state) => {
        state.loading = false;
      })
      .addCase(GetExistingTeacherAvailability.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetExistingTeacherAvailability.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload && action.payload.AvailableTimeSlots) {
          state.Teacher_Availabile_Booking_Slots = action.payload.AvailableTimeSlots;
        } else {
          state.error = "No available time slots";
          toast.error("No Bookings are Available For Now", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
          });
        }
        console.log("API Response:", action.payload);
      })
      .addCase(GetExistingTeacherAvailability.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(GetExistingTeacherAvailability__oF_Package.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetExistingTeacherAvailability__oF_Package.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload && action.payload.data && action.payload.data.AvailableTimeSlots) {
          state.Teacher_Availabile_Booking_Slots = action.payload.data.AvailableTimeSlots;
        } else {
          toast.error("No Bookings are Available For Now", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
          });
        }
      })
      .addCase(GetExistingTeacherAvailability__oF_Package.rejected, (state) => {
        state.loading = false;
      })
      .addCase(SearchBookingbyStudentUsername.pending, (state) => {
        state.loading = true;
      })
      .addCase(SearchBookingbyStudentUsername.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload && action.payload.data && action.payload.data.foundedBooking) {
          state.Allbookinglist = action.payload.data.foundedBooking;
        } else {
          state.Allbookinglist = [];
          toast.warning("No Bookings are Available For this Student Username", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
          });
        }
      })
      .addCase(SearchBookingbyStudentUsername.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchExistingBookings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchExistingBookings.fulfilled, (state, action) => {
        state.loading = false;
        state.existingBookings = action.payload.bookings;
      })
      .addCase(fetchExistingBookings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch bookings';
      })
      .addCase(fetchBookingsExcludingStudent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBookingsExcludingStudent.fulfilled, (state, action) => {
        state.loading = false;
        state.bookingsExcludingStudent = action.payload;
      })
      .addCase(fetchBookingsExcludingStudent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch bookings excluding student';
      });
  },
});

export default bookingSlice.reducer;
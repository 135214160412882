import React, { useEffect, useState, useCallback } from "react";
import Calendar from "react-calendar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaRegQuestionCircle } from "react-icons/fa";
import { Tooltip, Modal, Button, List, Card, Table, Tag, Spin, Progress } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import OwncloudSignupFormPopup from "./OwncloudSignupFormPopup";
import moment from 'moment-timezone';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import Loader2 from "../Loader2";
import { getTeacherSessionStatus } from "../../store/actions/teacherSessionActions";
import {
  GetBookingsByStudentID,
  UpdatetheBookingStatus,
} from "../../store/actions/bookingActions";
import { listDriveFiles, downloadDriveFile } from '../../store/actions/studentsActions';
import YourMaterialModal2 from "./YourMaterialModel2";

const StudentDash = ({ dateObj }) => {
  const { t, i18n } = useTranslation("global");
  const wallet = useSelector(state => state.wallet);
  const [times, setTimes] = useState({ startTime: "", endTime: "" });
  const student = useSelector((state) => state.students.user);
  const Bookings = useSelector((state) => state.bookings.StudentID_Booking);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [OwncloudFormPopup, setOwncloudSignupFormPopup] = useState(false);
  const [isYourMaterialModalVisible, setIsYourMaterialModalVisible] = useState(false);
  const [date, setDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [fillterBookingData, setFillterBookingData] = useState(false);
  const [fillterDataValue, setFillterDataValue] = useState("");
  const [currentTime, setCurrentTime] = useState(new Date());
  const [showPrompt, setShowPrompt] = useState(false);
  const [utcTime, setUtcTime] = useState(moment.utc());
  const selectedTimezone = useSelector((state) => state.students.user.Timezone); 
  const [initialTimezone, setInitialTimezone] = useState('');
  const selectedHourFormat = useSelector((state) => state.students.user.HourFormat);
  const [hourFormat, setHourFormat] = useState(localStorage.getItem('hourFormat') || '24');

  const [isLoading, setIsLoading] = useState(true);
  const [isDispatchLoading, setIsDispatchLoading] = useState(false);
  const { driveFiles, driveLoading } = useSelector(state => state.students);
  const [isDriveModalVisible, setIsDriveModalVisible] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  
  const [isJoiningRoom, setIsJoiningRoom] = useState(false);
  const [currentBookingId, setCurrentBookingId] = useState(null);
  const [currentTeacherId, setCurrentTeacherId] = useState(null);
  const [teacherJoined, setTeacherJoined] = useState(false);
  const [waitTimeLeft, setWaitTimeLeft] = useState(30);
  const [buttonStates, setButtonStates] = useState([]);

  const checkTeacherStatus = useCallback(async () => {
    if (!currentBookingId || !currentTeacherId) return;

    try {
      const result = await dispatch(getTeacherSessionStatus({ 
        bookingId: currentBookingId, 
        teacherId: currentTeacherId 
      }));

      if (result.payload && result.payload.isActive) {
        setTeacherJoined(true);
      }
    } catch (error) {
      console.error("Error checking teacher status:", error);
    }
  }, [currentBookingId, currentTeacherId, dispatch]);

  useEffect(() => {
    const timer = setInterval(() => {
      setUtcTime(moment.utc());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const renderTimezoneClockAndInfo = () => {
    const localTime = utcTime.tz(selectedTimezone);
    return (
      <div className="timezone-clock">
        <div className="digital-clock">
          <span className="time">{localTime.format(hourFormat === '12' ? 'hh:mm:ss A' : 'HH:mm:ss')}</span>
        </div>
        <div className="timezone-info">
          <span className="timezone">{selectedTimezone}</span>
          <span className="utc-time">UTC: {utcTime.format('HH:mm')}</span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    let intervalId;
    if (isJoiningRoom && !teacherJoined) {
      intervalId = setInterval(checkTeacherStatus, 5000); // Check every 5 seconds
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isJoiningRoom, teacherJoined, checkTeacherStatus]);

  const convertToLocalTime = (date, time) => {
    return moment.utc(`${date} ${time}`).tz(selectedTimezone);
  }

  useEffect(() => {
    let timerId;
    if (teacherJoined) {
      timerId = setInterval(() => {
        setWaitTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timerId);
            setIsJoiningRoom(false);
            navigate(`/Student/room/meeting/${currentBookingId}`);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => {
      if (timerId) clearInterval(timerId);
    };
  }, [teacherJoined, currentBookingId, navigate]);

  const joinRoom = (id, teacherId) => {
    setCurrentBookingId(id);
    setCurrentTeacherId(teacherId);
    setIsJoiningRoom(true);
    setTeacherJoined(false);
    setWaitTimeLeft(30);
  };

  const handleCancelJoining = () => {
    setIsJoiningRoom(false);
    setCurrentBookingId(null);
    setCurrentTeacherId(null);
    setTeacherJoined(false);
    setWaitTimeLeft(30);
  };

  useEffect(() => {
    if (dateObj && dateObj.length > 0) {
      const firstEntry = dateObj[0];
      const dateKey = Object.keys(firstEntry)[0];
      const timeEntries = firstEntry[dateKey];

      if (timeEntries && timeEntries.length > 0) {
        const { start, end } = timeEntries[0];
        setTimes({ startTime: start || "", endTime: end || "" });
      }
    }
  }, [dateObj]);

  const mergeConsecutiveSlots = (bookings) => {
    if (!Array.isArray(bookings)) {
      console.error('Bookings is not an array:', bookings);
      return [];
    }
    
    const mergedBookings = [];
    let currentMergedBooking = null;

    bookings.forEach(booking => {
      if (!booking.Scheduled_Dates || !Array.isArray(booking.Scheduled_Dates)) {
        mergedBookings.push(booking);
        return;
      }

      booking.Scheduled_Dates.forEach(dateObj => {
        if (!dateObj || typeof dateObj !== 'object') {
          return;
        }
        
        const date = Object.keys(dateObj)[0];
        let timeSlots = dateObj[date];
        if (!Array.isArray(timeSlots)) {
          timeSlots = [timeSlots];
        }

        timeSlots.sort((a, b) => moment(a.start, 'HH:mm').diff(moment(b.start, 'HH:mm')));

        timeSlots.forEach(slot => {
          if (!currentMergedBooking) {
            currentMergedBooking = {
              ...booking,
              Scheduled_Dates: [{ [date]: [{ ...slot, isConsecutive: false }] }],
              isConsecutive: false
            };
          } else {
            const lastDate = Object.keys(currentMergedBooking.Scheduled_Dates[currentMergedBooking.Scheduled_Dates.length - 1])[0];
            const lastSlots = currentMergedBooking.Scheduled_Dates[currentMergedBooking.Scheduled_Dates.length - 1][lastDate];
            const lastSlot = lastSlots[lastSlots.length - 1];

            if (date === lastDate && moment(slot.start, 'HH:mm').isSame(moment(lastSlot.end, 'HH:mm'))) {
              lastSlot.end = slot.end;
              lastSlot.isConsecutive = true;
              currentMergedBooking.isConsecutive = true;
            } else {
              mergedBookings.push(currentMergedBooking);
              currentMergedBooking = {
                ...booking,
                Scheduled_Dates: [{ [date]: [{ ...slot, isConsecutive: false }] }],
                isConsecutive: false
              };
            }
          }
        });
      });
    });

    if (currentMergedBooking) {
      mergedBookings.push(currentMergedBooking);
    }

    return mergedBookings;
  };

  useEffect(() => {
    if (isDriveModalVisible && selectedBookingId) {
      dispatch(listDriveFiles(selectedBookingId));
    }
  }, [isDriveModalVisible, selectedBookingId, dispatch]);

  const showDriveModal = (bookingId) => {
    setSelectedBookingId(bookingId);
    setIsDriveModalVisible(true);
  };

  const handleMaterialsClick = (e, bookingId) => {
    e.stopPropagation();
    setSelectedBookingId(bookingId);
    setIsYourMaterialModalVisible(true);
  };

  const handleDownload = async (fileId) => {
    const blob = await dispatch(downloadDriveFile(fileId)).unwrap();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await dispatch(GetBookingsByStudentID(student._id));
      } catch (error) {
        console.error("Error fetching bookings:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [student._id, dispatch]);

  useEffect(() => {
    if (Array.isArray(Bookings) && Bookings.length > 0) {
      UpdateBooking_Status(Bookings);
    }
  }, [Bookings]);

  useEffect(() => {
    const fetchTimezone = async () => {
      setIsLoading(true);
      try {
        const ipResponse = await axios.get('https://api.ipify.org?format=json');
        const ipAddress = ipResponse.data.ip;

        const timezoneResponse = await axios.get(`https://ipinfo.io/${ipAddress}?token=132a6bee31081e`);
        const timezone = timezoneResponse.data.timezone;
        setInitialTimezone(timezone);

        const hasResponded = sessionStorage.getItem('timezonePromptResponded') || localStorage.getItem('timezonePromptResponded');
        if (!hasResponded && timezone !== student?.Timezone) {
          setShowPrompt(true);
        }
      } catch (error) {
        console.error('Error fetching timezone:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTimezone();
  }, [student?.Timezone]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderTime = (time, date) => {
    if (!time || !date) return '';
    return convertToLocalTime(date, time).format(hourFormat === '12' ? 'hh:mm A' : 'HH:mm');
  };

  const totalLectures = Bookings?.length || 0;
  const completedSessions = Bookings?.filter(booking => booking?.Status === "Completed").length || 0;
  const pendingOrScheduledSessions = Bookings?.filter(booking => booking?.Status === "Rescheduled" || booking?.Status === "Scheduled").length || 0;
  const cancelledSessions = Bookings?.filter(booking => booking?.Status === "Cancelled").length || 0;

  const roomHandler = (bookingId, teacherId) => {
    joinRoom(bookingId, teacherId);
  };

  const DispatchHandler = (bookingId) => {
    setSelectedBookingId(bookingId);
    setIsDriveModalVisible(true);
  };

  const tileContent = ({ date, view }) => {
    if (view === "month") {
      const dateString = moment(date).format('YYYY-MM-DD');
      for (const booking of Bookings || []) {
        if (booking?.Scheduled_Dates && Array.isArray(booking.Scheduled_Dates)) {
          for (const scheduledDateObj of booking.Scheduled_Dates) {
            if (scheduledDateObj && typeof scheduledDateObj === 'object') {
              const dates = Object.keys(scheduledDateObj);
              for (const scheduledDate of dates) {
                const scheduledDateString = convertToLocalTime(scheduledDate, scheduledDateObj[scheduledDate][0].start).format('YYYY-MM-DD');
                if (scheduledDateString === dateString) {
                  return <p className="bg-success text-white">L</p>;
                }
              }
            }
          }
        }
      }
    }
    return null;
  };

  const handleCalendarClick = (value) => {
    const formattedDate = moment(value).format('YYYY-MM-DD');
    setSelectedDate(formattedDate === selectedDate ? null : formattedDate);
  };

  const getFilteredAndSortedBookings = () => {
    let filteredBookings = Bookings || [];

    if (selectedDate) {
      filteredBookings = filteredBookings.filter(booking => 
        booking?.Scheduled_Dates?.some(dateObj => {
          if (dateObj && typeof dateObj === 'object') {
            return Object.keys(dateObj).some(date => 
              moment(date).format('YYYY-MM-DD') === selectedDate
            );
          }
          return false;
        })
      );
    }

    const sortedBookings = [...filteredBookings].sort((a, b) => {
      const earliestDateA = a.Scheduled_Dates?.[0] ? Object.keys(a.Scheduled_Dates[0])[0] : null;
      const earliestDateB = b.Scheduled_Dates?.[0] ? Object.keys(b.Scheduled_Dates[0])[0] : null;
      return new Date(earliestDateA) - new Date(earliestDateB);
    });

    return mergeConsecutiveSlots(sortedBookings);
  };

  const handlePromptResponse = (response) => {
    if (response === 'yes') {
      navigate(`/Student-dashboard/profile`);
    } else if (response === 'no') {
      localStorage.setItem('userTimezone', initialTimezone);
    }
    sessionStorage.setItem('timezonePromptResponded', 'true');
    localStorage.setItem('timezonePromptResponded', 'true');
    setShowPrompt(false);
  };

  const UpdateBooking_Status = async (Bookings) => {
    const FoundedBooking = Bookings.filter(
      (booking) => booking.Status === "Scheduled"
    );
    const TodayDate = new Date();
    const options = {
      weekday: "short",
      month: "short",
      day: "2-digit",
      year: "numeric",
    };
    const formattedTodayDate = TodayDate.toLocaleDateString("en-US", options);
    const formattedTodayDateWithoutComma = formattedTodayDate.replace(/,/g, "");
    
    for (const booking of FoundedBooking) {
      if (booking?.Scheduled_Dates && Array.isArray(booking.Scheduled_Dates)) {
        for (const scheduledDateObj of booking.Scheduled_Dates) {
          if (scheduledDateObj && typeof scheduledDateObj === 'object') {
            const dates = Object.keys(scheduledDateObj);
            for (const date of dates) {
              const timeSlots = scheduledDateObj[date];
              if (Array.isArray(timeSlots)) {
                for (const slot of timeSlots) {
                  let slotDate = new Date(date);
                  let todayDate = new Date(formattedTodayDateWithoutComma);
                  if (todayDate > slotDate) {
                    console.log("Today's date is greater than slot date");
                    console.log(booking);
                    dispatch(UpdatetheBookingStatus(booking._id));
                  } else {
                    console.log("Today's date is not greater than slot date");
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  const isWithinScheduledTime = useCallback(async (scheduledDate, startTimeUTC, endTimeUTC) => {
    if (!scheduledDate || !startTimeUTC || !endTimeUTC) {
      console.error("Missing required parameters", { scheduledDate, startTimeUTC, endTimeUTC });
      return false;
    }
  
    // Create full date-time strings in UTC
    const startDateTimeUTC = moment.utc(`${scheduledDate} ${startTimeUTC}`, 'YYYY-MM-DD HH:mm');
    const endDateTimeUTC = moment.utc(`${scheduledDate} ${endTimeUTC}`, 'YYYY-MM-DD HH:mm');
  
    // If end time is on the next day, add a day to endDateTimeUTC
    if (endDateTimeUTC.isBefore(startDateTimeUTC)) {
      endDateTimeUTC.add(1, 'day');
    }
  
    const tenMinutesBeforeUTC = startDateTimeUTC.clone().subtract(10, 'minutes');
  
    try {
      // Fetch current UTC time from WorldTimeAPI
      const response = await axios.get('http://worldtimeapi.org/api/timezone/Etc/UTC');
      const serverTimeUTC = moment.utc(response.data.utc_datetime);
  
      console.log('Debug isWithinScheduledTime:');
      console.log('WorldTimeAPI UTC:', serverTimeUTC.format());
      console.log('Scheduled Date:', scheduledDate);
      console.log('Start Time (UTC):', startDateTimeUTC.format());
      console.log('End Time (UTC):', endDateTimeUTC.format());
      console.log('10 Minutes Before (UTC):', tenMinutesBeforeUTC.format());
      console.log('Is After or Equal to 10 Minutes Before:', serverTimeUTC.isSameOrAfter(tenMinutesBeforeUTC));
      console.log('Is Before or Equal to End:', serverTimeUTC.isSameOrBefore(endDateTimeUTC));
  
      return serverTimeUTC.isSameOrAfter(tenMinutesBeforeUTC) && serverTimeUTC.isSameOrBefore(endDateTimeUTC);
    } catch (error) {
      console.error('Error fetching WorldTimeAPI time:', error);
      // Fallback to local UTC time if API call fails
      const fallbackTimeUTC = moment.utc();
      console.warn('Using fallback local UTC time:', fallbackTimeUTC.format());
      return fallbackTimeUTC.isSameOrAfter(tenMinutesBeforeUTC) && fallbackTimeUTC.isSameOrBefore(endDateTimeUTC);
    }
  }, []);

  useEffect(() => {
    const updateButtonStates = async () => {
      const newButtonStates = await Promise.all(Bookings?.map(async (booking) => {
        let isEnabled = false;
        if (booking?.Scheduled_Dates && Array.isArray(booking.Scheduled_Dates)) {
          for (const dateObj of booking.Scheduled_Dates) {
            if (dateObj && typeof dateObj === 'object') {
              const dates = Object.keys(dateObj);
              for (const date of dates) {
                const timeSlots = dateObj[date];
                if (Array.isArray(timeSlots) && timeSlots.length > 0) {
                  const slot = timeSlots[0];
                  if (slot && slot.start && slot.end) {
                    isEnabled = await isWithinScheduledTime(date, slot.start, slot.end);
                    if (isEnabled) break;
                  }
                }
              }
            }
            if (isEnabled) break;
          }
        }
        return isEnabled;
      }) || []);
      setButtonStates(newButtonStates);
    };

    updateButtonStates();
    const intervalId = setInterval(updateButtonStates, 60000); // Update every minute

    return () => clearInterval(intervalId);
  }, [Bookings, isWithinScheduledTime]);

  const closeOwncloudSignupFormPopup = () => {
    setOwncloudSignupFormPopup(false);
  };

  const renderDesktopMeetings = () => {
    const columns = [
      {
        title: '#',
        dataIndex: 'index',
        key: 'index',
        render: (text, record, index) => index + 1,
      },
      {
        title: t("StudentDash.tableheading1"),
        dataIndex: 'teacher',
        key: 'teacher',
        render: (text, record) => record.Teacher_ID?.map((teacher) => teacher?.Username).join(", "),
      },
      {
        title: t("StudentDash.tableheading2"),
        dataIndex: 'scheduledDates',
        key: 'scheduledDates',
        render: (text, record) => (
          <div>
            {record.Scheduled_Dates?.map((dateObj, dateIndex) => {
              const date = Object.keys(dateObj)[0];
              const timeSlots = dateObj[date];
              return (
                <div key={`${dateIndex}-${date}`}>
                  <p>{convertToLocalTime(date, timeSlots[0].start).format('LL')}</p>
                  <ul>
                    {timeSlots.map((slot, slotIndex) => (
                      <li key={`${dateIndex}-${date}-${slotIndex}`}>
                        {convertToLocalTime(date, slot.start).format(hourFormat === '12' ? 'hh:mm A' : 'HH:mm')} - 
                        {convertToLocalTime(date, slot.end).format(hourFormat === '12' ? 'hh:mm A' : 'HH:mm')}
                      </li>
                    ))}
                  </ul>
                  {record.isConsecutive && (
                    <Tag color="blue">
                      {t("StudentDash.ConsecutiveBooking")}
                    </Tag>
                  )}
                </div>
              );
            })}
          </div>
        ),
      },
      {
        title: t("StudentDash.tableheading4"),
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => (
          <span className={record.Status === "Cancelled" ? "cancelled-status" : ""}>
            {t(`BookingStatus.${record.Status}`)}
          </span>
        ),
      },
      {
        title: t("StudentDash.Actions"),
        key: 'actions',
        render: (text, record, index) => {
          const scheduledDate = Object.keys(record.Scheduled_Dates[0])[0];
          const startTime = record.Scheduled_Dates[0][scheduledDate][0].start;
          const endTime = record.Scheduled_Dates[0][scheduledDate][0].end;
          
          return (
            <div>
              <Button
                onClick={() => roomHandler(record._id, record.Teacher_ID[0]._id)}
                className={`join-room-btn ${buttonStates[index] ? "btn-success" : "btn-secondary"}`}
                disabled={!buttonStates[index]}
              >
                {t("StudentDash.JoinRoom")}
              </Button>
              {record.Status !== "Cancelled" && !buttonStates[index] && (
                <Tooltip title={t("StudentDash.JoinRoomTooltip")}>
                  <FaRegQuestionCircle className="question-icon" />
                </Tooltip>
              )}
              <Button onClick={(e) => handleMaterialsClick(e, record._id)}>
                {t("StudentDash.YourMaterial")}
              </Button>
            </div>
          );
        }
      },
    ];

    return (
      <Table 
        dataSource={getFilteredAndSortedBookings()} 
        columns={columns} 
        rowKey="_id"
        className="desktop-meetings-table"
      />
    );
  };

  const renderMobileMeetings = () => {
    return (
      <List
        dataSource={getFilteredAndSortedBookings()}
        renderItem={(Booking, index) => (
          <Card 
            key={Booking?._id}
            className={`meeting-card ${Booking?.Status === "Cancelled" ? "cancelled-card" : ""}`}
            title={`${t("StudentDash.Meeting")} ${index + 1}`}
          >
            <p><strong>{t("StudentDash.tableheading1")}:</strong> {Booking?.Teacher_ID?.map((teacher) => teacher?.Username).join(", ")}</p>
            <p><strong>{t("StudentDash.tableheading4")}:</strong> {t(`BookingStatus.${Booking?.Status}`)}</p>
            {Booking?.Scheduled_Dates?.map((dateObj, dateIndex) => {
              const date = Object.keys(dateObj)[0];
              const timeSlots = dateObj[date];
              return (
                <div key={`${dateIndex}-${date}`}>
                  <p><strong>{convertToLocalTime(date, timeSlots[0].start).format('LL')}</strong></p>
                  <ul className="meeting-timeslots">
                    {timeSlots.map((slot, slotIndex) => (
                      <li key={`${dateIndex}-${date}-${slotIndex}`}>
                        {convertToLocalTime(date, slot.start).format(hourFormat === '12' ? 'hh:mm A' : 'HH:mm')} - 
                        {convertToLocalTime(date, slot.end).format(hourFormat === '12' ? 'hh:mm A' : 'HH:mm')}
                      </li>
                    ))}
                  </ul>
                  {Booking.isConsecutive && (
                    <Tag color="blue">
                      {t("StudentDash.ConsecutiveBooking")}
                    </Tag>
                  )}
                </div>
              );
            })}
            <Button
              onClick={() => roomHandler(Booking._id, Booking.Teacher_ID[0]._id)}
              className={`join-room-btn ${buttonStates[index] ? "btn-success" : "btn-secondary"}`}
              disabled={!buttonStates[index]}
            >
              {t("StudentDash.JoinRoom")}
            </Button>
            {Booking.Status !== "Cancelled" && !buttonStates[index] && (
              <Tooltip title={t("StudentDash.JoinRoomTooltip")}>
                <FaRegQuestionCircle className="question-icon" />
              </Tooltip>
            )}
            <Button 
              className="materials-btn" 
              onClick={(e) => handleMaterialsClick(e, Booking._id)}
            >
              {t("StudentDash.YourMaterial")}
            </Button>
          </Card>
        )}
      />
    );
  };

  if (isLoading) {
    return (
      <Loader2 
        loading={true}
        text={t("common.Loading")}
        fullScreen={true}
        size={300}
        customStyles={{
          backgroundColor: 'rgba(15, 15, 15, 0.5)'
        }}
      />
    );
  }
    
  return (
    <>
      <div className="Dash_mainPage_style">
        <h6>{t("StudentDash.upperhead1")}</h6>
        {renderTimezoneClockAndInfo()}
        <div className="Admin-Dash_contnet_box">
          <div className="Admin-Dash_contnet_section_div">
            <img
              className="Admin-Dash_contnet_div_img"
              src="https://img.freepik.com/free-vector/university-student-cap-mortar-board-diploma_3446-334.jpg?uid=R132339509&ga=GA1.1.1941482743.1703671287&semt=sph"
              alt=""
            />
            <span className="Admin-Dash_contnet_head_div_span">
              {t("StudentDash.upperhead2")}
            </span>
            <span style={{ color: "grey" }}>{totalLectures}</span>
          </div>
          <div className="Admin-Dash_contnet_section_div">
            <img
              className="Admin-Dash_contnet_head_div_img"
              src="https://cdn-icons-png.flaticon.com/128/9517/9517233.png?uid=R132339509&ga=GA1.1.1941482743.1703671287&semt=ais"
              alt=""
            />
            <span className="Admin-Dash_contnet_head_div_span">
              {t("StudentDash.upperhead3")}
            </span>
            <span style={{ color: "grey" }}>{completedSessions}</span>
          </div>
          <div className="Admin-Dash_contnet_section_div">
            <img
              className="Admin-Dash_contnet_head_div_img"
              src="https://cdn-icons-png.flaticon.com/128/609/609183.png?uid=R132339509&ga=GA1.2.1941482743.1703671287"
              alt=""
            />
            <span className="Admin-Dash_contnet_head_div_span">
              {t("StudentDash.upperhead4")}
            </span>
            <span style={{ color: "grey" }}>{pendingOrScheduledSessions}</span>
          </div>
          <div className="Admin-Dash_contnet_section_div">
            <img
              className="Admin-Dash_contnet_head_div_img"
              src="https://cdn-icons-png.flaticon.com/128/1329/1329416.png?uid=R132339509&ga=GA1.2.1941482743.1703671287"
              alt=""
            />
            <span className="Admin-Dash_contnet_head_div_span">
              {t("StudentDash.upperhead5")}
            </span>
            <span style={{ color: "grey" }}>{cancelledSessions}</span>
          </div>
          <div className="Admin-Dash_contnet_section_div">
            <img
              className="Admin-Dash_contnet_head_div_img"
              src="https://cdn-icons-png.flaticon.com/128/2321/2321104.png"
              alt=""
            />
            <span className="Admin-Dash_contnet_head_div_span">
              {t("StudentDash.RemainingHours")}
            </span>
            <span style={{ color: "grey" }}>{wallet.availableHours}</span>
          </div>
        </div>
        <div className="Admin-Dash_list_box">
          <div className="Admin-Dash_student_list_box">
            <h6>{t("StudentDash.Meetings")}</h6>
            <div className="Admin-Dash_student_list_div">
              {isMobile ? renderMobileMeetings() : renderDesktopMeetings()}
            </div>
          </div>
          <div className="Admin-Dash_student_calender_box">
            <h6>{t("StudentDash.Events")}</h6>
            <br />
            <Calendar
              value={date}
              prev2Label={false}
              next2Label={false}
              tileContent={tileContent}
              onChange={handleCalendarClick}
              locale={i18n.language}
            />
            {selectedDate && (
              <p>{t("StudentDash.ShowingMeetingsFor", { date: convertToLocalTime(selectedDate, "00:00").format('LL') })}</p>
            )}
          </div>
        </div>
      </div>
  
      {showPrompt && (
        <div className="timezone-prompt-overlay">
          <div className="timezone-prompt">
            <p>{t("StudentDash.TimezonePrompt", { timezone: initialTimezone })}</p>
            <button onClick={() => handlePromptResponse('yes')}>{t("common.Yes")}</button>
            <button onClick={() => handlePromptResponse('no')}>{t("common.No")}</button>
          </div>
        </div>
      )}

      {OwncloudFormPopup && (
        <OwncloudSignupFormPopup handleClose={closeOwncloudSignupFormPopup} />
      )}

      <YourMaterialModal2
        isVisible={isYourMaterialModalVisible}
        onClose={() => setIsYourMaterialModalVisible(false)}
        bookingId={selectedBookingId}
      />

      <Modal
        title={t("StudentDash.DriveFiles")}
        visible={isDriveModalVisible}
        onCancel={() => setIsDriveModalVisible(false)}
        footer={null}
      >
        {driveLoading ? (
          <p>{t("common.Loading")}</p>
        ) : (
          <List
            dataSource={driveFiles}
            renderItem={(file) => (
              <List.Item
                actions={[
                  <Button
                    icon={<DownloadOutlined />}
                    onClick={() => handleDownload(file.id)}
                  >
                    {t("StudentDash.Download")}
                  </Button>
                ]}
              >
                <List.Item.Meta
                  title={file.name}
                  description={`${t("StudentDash.Size")}: ${file.size} bytes`}
                />
              </List.Item>
            )}
          />
        )}
      </Modal>

      <Modal
        visible={isJoiningRoom}
        title={t("StudentDash.JoiningRoom")}
        footer={null}
        onCancel={handleCancelJoining}
      >
        <div style={{ textAlign: 'center' }}>
          {!teacherJoined ? (
            <>
              <Spin size="large" />
              <p style={{ marginTop: 16 }}>{t("StudentDash.WaitingForTeacher")}</p>
            </>
          ) : (
            <>
              <Progress type="circle" percent={Math.round((30 - waitTimeLeft) / 30 * 100)} />
              <p style={{ marginTop: 16 }}>{t("StudentDash.TeacherJoined", { seconds: waitTimeLeft })}</p>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default StudentDash;